import {VacasaBroadcastService} from "@common/services";
import {BroadcastReason, DataType, BroadcastCreated} from "@common/typing";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {BroadcastData} from "../types/broadcast";
import {DEFAULT_SMS_MESSAGE} from "../constants/constants";
import {datadogLogs} from "@datadog/browser-logs";

export const createBroadcast = createAsyncThunk("broadcast/createBroadcast", async (data: BroadcastData, {getState, rejectWithValue}) => {
    const state = getState() as any;
    const consumers = [...data?.data?.attributes?.consumer_segmentations];
    const consumersUpdated = consumers.map((consumer) => {
        const isSmsChecked = state.broadcast.data.form.consumerSegmentation[consumer.type].smsText.checked;
        return {
            subject_line: state.broadcast.data.form.consumerSegmentation[consumer.type].email.subject,
            consumer_affected: consumer.type,
            email: state.broadcast.data.form.consumerSegmentation[consumer.type].email.checked,
            email_message: state.broadcast.data.form.consumerSegmentation[consumer.type].email.message,
            sms: state.broadcast.data.form.consumerSegmentation[consumer.type].smsText.checked,
            sms_message: isSmsChecked ?
                state.broadcast.data.form.consumerSegmentation[consumer.type].smsText.message :
                "",
            airbnb_chat: state.broadcast.data.form.consumerSegmentation[consumer.type].airbnbChat.checked,
            airbnb_chat_message: state.broadcast.data.form.consumerSegmentation[consumer.type].airbnbChat.message,
        }
    });

    const broadcastData: Partial<any> = {
        ...data,
        data: {
            ...data.data,
            attributes: {
                ...data.data.attributes,
                consumer_segmentations: consumersUpdated
            }
        }
    };

    const vacasaBroadCastService = VacasaBroadcastService.getInstance();
    try {
        const response = await vacasaBroadCastService.createBroadcast(broadcastData);
        datadogLogs.logger.info("Broadcast created successfully.", response.data);
        return response.data;
    } catch (error) {
        datadogLogs.logger.error("Error while creating a new broadcast", error);
        return rejectWithValue(error);
    }
});

export const getBroadcastReasons = createAsyncThunk("broadcast/getReasons", async (_, {rejectWithValue}) => {
    const vacasaBroadCastService = VacasaBroadcastService.getInstance();
    try {
        const response = await vacasaBroadCastService.getReasons();
        return response.data;
    } catch (error) {
        datadogLogs.logger.error("Error while getting the broadcast reasons", error);
        return rejectWithValue(error);
    }
});

export const disableBroadcast = createAsyncThunk("broadcast/disableBroadcast", async (data: {id: number; payload: any}) => {
    const vacasaBroadCastService = VacasaBroadcastService.getInstance();
    const response = await vacasaBroadCastService.disableBroadcast(data.id,data.payload);
    return response.data;
});

export const getBroadcastById = createAsyncThunk("broadcast/getBroadcastById", async (id: number) => {
    const vacasaBroadCastService = VacasaBroadcastService.getInstance();
    const response = await vacasaBroadCastService.getBroadcastById(id);
    return response.data;
});

export const validateUnit = createAsyncThunk("broadcast/validateUnit", async (unitCodes: string | string[]) => {
    const arrayOfUnitCodes = typeof unitCodes === "string" ? [unitCodes] : unitCodes;
    const data = {
        data: {
            type: "validate-units",
            attributes: {
                unit_codes: arrayOfUnitCodes
            }
        }
    };
    const vacasaBroadCastService = VacasaBroadcastService.getInstance();
    try {
        const response = await vacasaBroadCastService.validateUnit(data);
        return response;
    } catch (error) {
        datadogLogs.logger.error("Error while validating the unit codes", error);
    }
});

export type ImpactedUnit = {
    id: number;
    code: string;
};

export type ConsumerSegmentationType = {
    type?: string;
    email: {
        checked: boolean;
        subject: string;
        message: string;
        disabled?: boolean;
    };
    airbnbChat: {
        checked: boolean;
        message: string;
        disabled?: boolean;
    };
    smsText: {
        checked: boolean;
        type: string;
        message: string;
        disabled?: boolean;
    };
};

type InitialState = {
    reasons: BroadcastReason[];
    broadcastCase: BroadcastCreated;
    isLoading: boolean;
    actions: {
        submitting: boolean;
        deactivating: boolean;
        fetching: boolean;
        validatingUnits: boolean;
    };
    modals: {
        deactivateModal: boolean;
        updateDatesModal: boolean;
        broadcastErrorModal: boolean;
    };
    data: {
        form: {
            incidentTitle: string;
            impactedUnits: {
                validUnits: ImpactedUnit[];
                invalidUnits: [];
            };
            startDate: string;
            endDate: string;
            reasonsSelected: number[];
            messageType: {
                channel: {
                    checked: boolean;
                    message: string;
                };
                internal: {
                    checked: boolean;
                    message: string;
                };
                embed: {
                    checked: boolean;
                    message: string;
                };
            };
            guest_in_home: {
                checked: boolean;
            };
            upcoming_arrivals: {
                checked: boolean;
            };
            new_booking: {
                checked: boolean;
            };
            consumerSegmentation: {
                guest_in_home: ConsumerSegmentationType;
                upcoming_arrivals: ConsumerSegmentationType;
                new_booking: ConsumerSegmentationType;
            };
        }
    };
    states: {
        isReview: boolean;
        isAnActiveCase: boolean;
        impactedUnitsDuplicated: boolean;
        broadcastCreated: boolean;
    };
};

const initialState: InitialState = {
    reasons: [],
    broadcastCase: null,
    isLoading: false,
    actions: {
        submitting: false,
        deactivating: false,
        fetching: true,
        validatingUnits: false,
    },
    modals: {
        deactivateModal: false,
        updateDatesModal: false,
        broadcastErrorModal: false,
    },
    data: {
        form: {
            incidentTitle: "",
            impactedUnits: {
                validUnits: [],
                invalidUnits: [],
            },
            startDate: "",
            endDate: "",
            reasonsSelected: [],
            messageType: {
                channel: {
                    checked: false,
                    message: "",
                },
                internal: {
                    checked: false,
                    message: "",
                },
                embed: {
                    checked: false,
                    message: "",
                },
            },
            guest_in_home: {
                checked: false,
            },
            upcoming_arrivals: {
                checked: false,
            },
            new_booking: {
                checked: false,
            },
            consumerSegmentation: {
                guest_in_home: {
                    email: {
                        checked: true,
                        subject: "",
                        message: "",
                        disabled: true,
                    },
                    airbnbChat: {
                        checked: false,
                        message: "",
                        disabled: false,
                    },
                    smsText: {
                        checked: false,
                        type: "default",
                        message: DEFAULT_SMS_MESSAGE,
                        disabled: false,
                    },
                },
                upcoming_arrivals: {
                    email: {
                        checked: true,
                        subject: "",
                        message: "",
                        disabled: true,
                    },
                    airbnbChat: {
                        checked: false,
                        message: "",
                        disabled: false,
                    },
                    smsText: {
                        checked: false,
                        type: "default",
                        message: DEFAULT_SMS_MESSAGE,
                        disabled: false,
                    },
                },
                new_booking: {
                    email: {
                        checked: true,
                        subject: "",
                        message: "",
                        disabled: true,
                    },
                    airbnbChat: {
                        checked: false,
                        message: "",
                        disabled: false,
                    },
                    smsText: {
                        checked: false,
                        type: "default",
                        message: DEFAULT_SMS_MESSAGE,
                        disabled: false,
                    },
                },
            },
        }
    },
    states: {
        isReview: false,
        isAnActiveCase: false,
        impactedUnitsDuplicated: false,
        broadcastCreated: false,
    },
};

const broadcastSlice = createSlice({
    name: "broadcast",
    initialState,
    reducers: {
        resetForm: (state) => {
            state.states = {
                ...initialState.states,
            };
            state.actions = {
                ...initialState.actions
            };
        },
        setBroadcastCaseStatus: (state, {payload}) => {
            state.states.isAnActiveCase = payload;
        },
        setIncidentTitle: (state, {payload}) => {
            state.data.form.incidentTitle = payload;
        },
        setImpactedUnits: (state, {payload}) => {
            state.data.form.impactedUnits = payload;
        },
        setStartDate: (state, {payload}) => {
            state.data.form.startDate = payload;
        },
        setEndDate: (state, {payload}) => {
            state.data.form.endDate = payload;
        },
        setReasonsSelected: (state, {payload}) => {
            state.data.form.reasonsSelected = payload;
        },
        setMessageType: (state, {payload}) => {
            state.data.form.messageType = {...state.data.form.messageType, ...payload};
        },
        setTypeOfRecipients: (state, {payload}) => {
            const {recipientType, checked} = payload;
            state.data.form[recipientType].checked = checked;
            state.data.form.consumerSegmentation[recipientType] = {...initialState.data.form.consumerSegmentation[recipientType]};
        },
        setGuestInHomeConsumer: (state, {payload}) => {
            state.data.form.consumerSegmentation.guest_in_home = {...state.data.form.consumerSegmentation.guest_in_home, ...payload};
        },
        setUpcomingArrivalsConsumer: (state, {payload}) => {
            state.data.form.consumerSegmentation.upcoming_arrivals = {...state.data.form.consumerSegmentation.upcoming_arrivals, ...payload};
        },
        setNewBookingConsumer: (state, {payload}) => {
            state.data.form.consumerSegmentation.new_booking = {...state.data.form.consumerSegmentation.new_booking, ...payload};
        },
        setDeactivateModalStatus: (state, {payload}) => {
            state.modals.deactivateModal = payload;
        },
        setUpdateDatesModalStatus: (state, {payload}) => {
            state.modals.updateDatesModal = payload;
        },
        setBroadcastErrorModalStatus: (state, {payload}) => {
            state.modals.broadcastErrorModal = payload;
        },
        setIsReview: (state, {payload}) => {
            state.states.isReview = payload;
        },
        disableConsumerCheckboxes: (state, {payload}) => {
            const {consumer, method, disabled} = payload;
            state.data.form.consumerSegmentation[consumer][method].disabled = disabled;
        },
        setEmailSubject: (state, {payload}) => {
            const {consumer, subject} = payload;
            state.data.form.consumerSegmentation[consumer].email.subject = subject;
        },
        setEmailMessage: (state, {payload}) => {
            const {consumer, message} = payload;
            state.data.form.consumerSegmentation[consumer].email.message = message;
        },
        setAirbnbMessage: (state, {payload}) => {
            const {consumer, message} = payload;
            state.data.form.consumerSegmentation[consumer].airbnbChat.message = message;
        },
        setSmsMessage: (state, {payload}) => {
            const {consumer, message} = payload;
            state.data.form.consumerSegmentation[consumer].smsText.message = message;
        },
        setImpactedUnitsDuplicated: (state, {payload}) => {
            state.states.impactedUnitsDuplicated = payload;
        },
        cleanInvalidUnitCodes: (state) => {
            state.data.form.impactedUnits = {validUnits: state.data.form.impactedUnits.validUnits, invalidUnits: []};
        },
        cleanBroadcastData : (state) => {
            state.broadcastCase = null;
        },
        cleanFormData : (state) => {
            state.data.form = initialState.data.form;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getBroadcastReasons.fulfilled, (state: any, {payload}) => {
                state.actions.fetching = false;
                state.states.broadcastErrorModal = false;
                state.reasons = payload;
            })
            .addCase(getBroadcastReasons.rejected, (state: any) => {
                state.actions.fetching = false;
                state.states.broadcastErrorModal = true;
            })
            .addCase(createBroadcast.pending, (state: any) => {
                state.actions.submitting = true;
            })
            .addCase(createBroadcast.fulfilled, (state: any) => {
                state.states.broadcastCreated = true;
                state.states.broadcastErrorModal = false;
                state.actions.submitting = false;
            })
            .addCase(createBroadcast.rejected, (state: any) => {
                state.states.creationFailed = true;
                state.actions.submitting = false;
                state.states.broadcastErrorModal = true;
            })
            .addCase(getBroadcastById.pending, (state: any) => {
                state.isLoading = true;
            })
            .addCase(getBroadcastById.fulfilled, (state: any, {payload}) => {
                state.isLoading = false;
                state.broadcastCase = payload;
            })
            .addCase(disableBroadcast.pending, (state: any) => {
                state.isLoading = true;
            })
            .addCase(disableBroadcast.fulfilled, (state: any, {payload}) => {
                state.isLoading = false;
                state.broadcastCase = payload;
            })
            .addCase(validateUnit.pending, (state: any) => {
                state.actions.validatingUnits = true;
            })
            .addCase(validateUnit.rejected, (state: any) => {
                state.actions.validatingUnits = false;
            })
            .addCase(validateUnit.fulfilled, (state: any) => {
                state.actions.validatingUnits = false;
            })
    },
});

export const {
    setIncidentTitle,
    setImpactedUnits,
    setStartDate,
    setEndDate,
    setReasonsSelected,
    setMessageType,
    setGuestInHomeConsumer,
    setUpcomingArrivalsConsumer,
    setTypeOfRecipients,
    setNewBookingConsumer,
    setDeactivateModalStatus,
    setUpdateDatesModalStatus,
    setBroadcastErrorModalStatus,
    setIsReview,
    setBroadcastCaseStatus,
    disableConsumerCheckboxes,
    setEmailSubject,
    setEmailMessage,
    setAirbnbMessage,
    setSmsMessage,
    setImpactedUnitsDuplicated,
    cleanInvalidUnitCodes,
    cleanBroadcastData,
    resetForm,
    cleanFormData,
} = broadcastSlice.actions;

export default broadcastSlice.reducer;
