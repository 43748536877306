import {configureStore} from "@reduxjs/toolkit";
import broadcastReducer from "./broadcastSlice";
import queueReducer from "./queueSlice";
import {useDispatch} from "react-redux";

export const store = configureStore({
    reducer: {
        broadcast: broadcastReducer,
        queue: queueReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
