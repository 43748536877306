import React, {useEffect, useState} from "react";
import localStyles from "./BroadcastReasons.module.scss";
import {Select, Tag} from "antd";
import {RootState, useAppDispatch} from "../../store/store";
import {setIsReview, setReasonsSelected} from "../../store/broadcastSlice";
import {useSelector} from "react-redux";
import {FormOutlined} from "@ant-design/icons";
export const BroadcastReasons = ({ readOnly, onError }: { readOnly: boolean, onError: Function }): React.JSX.Element => {
    //RTK State
    const dispatch = useAppDispatch();
    const isReview = useSelector((state: RootState) => state.broadcast.states.isReview);
    const listOfReasons = useSelector((state: RootState) => state.broadcast.reasons);
    const broadcast = useSelector((state: RootState) => state.broadcast.broadcastCase);

    //Local State
    const [reasons, setReasons] = useState<string[]>([]);
    const [reasonsIds, setReasonsIds] = useState<number[]>([]);
    const [tagInput, setTagInput] = useState<string>('Search...');

    //Handlers
    const handleSelectChange = (value: string) => {
        const reasonFiltered = listOfReasons?.find((reason) => reason?.id === parseInt(value));
        if(reasons?.includes(value) || reasonsIds?.includes(reasonFiltered?.id)){
            setTagInput('Search...');
            return;
        };

        if (reasonFiltered.attributes.is_active) {
            setReasons((prevSelectedItems) => [...prevSelectedItems, reasonFiltered?.attributes?.name]);
            setReasonsIds((prevSelectedItems) => [...prevSelectedItems, reasonFiltered?.id]);
        } else {
          onError();
        }
        setTagInput('Search...');
    };

    const handleTagInputChange = (value: string) => {
        setTagInput(value);
    };

    const handleTagClose = (reasonClosed: string) => {
        const newReasons = reasons.filter((reason) => reason !== reasonClosed);
        const reasonIdFiltered = listOfReasons.find((reason) => reason?.attributes?.name === reasonClosed);
        const newReasonsIds = reasonsIds.filter((reason) => reason !== reasonIdFiltered.id);
        setReasons(newReasons);
        setReasonsIds(newReasonsIds);
    };

    //Effects
    useEffect(() => {
        dispatch(setReasonsSelected(reasonsIds));
    }, [reasons, reasonsIds]);

    //Store broadcast detail reasons
    useEffect(() => {
        if(broadcast){
            const reasons: string[] = broadcast.attributes.reasons.map(reason => reason.name);
            setReasons(reasons);
        }
    }, [broadcast]);

    return (
        <>
            <div className="review-container">
                <span className="broadcast-section-title">Broadcast Reason(s)</span>
                {isReview ? <FormOutlined onClick={() => dispatch(setIsReview(false))} className="edit-icon" /> : null}
            </div>
            <p className={localStyles.subTitle}>Select as many applicable reasons as you'd like.</p>
            <div className={localStyles.broadcastReasonsContainer}>
                {!isReview && !readOnly
                    ? <>
                        <div className={localStyles.inputsSection}>
                            <label className="input-label">Choose Reason(s)</label>
                            <div className={localStyles.selectReason}>
                                <Select<string | number, { value: string; children: string }>
                                    showSearch
                                    style={{ width: 200 }}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={handleSelectChange}
                                    value={tagInput}
                                    onSearch={handleTagInputChange}
                                >
                                    {
                                        listOfReasons?.map((reason) => (
                                            <Select.Option key={reason.id} value={reason.id}>{reason?.attributes?.name}</Select.Option>
                                        ))
                                    }
                                </Select>
                            </div>
                        </div>
                        <div className={localStyles.reasonListSection}>
                            <span className="input-label">List of Broadcast Reason(s)</span>
                            <div className={localStyles.reasonListInput} aria-disabled>
                                {reasons?.map((reason) => (
                                        reasons?.length > 0 ? <Tag key={reason} onClose={() => handleTagClose(reason)} closable={true}>{reason}</Tag> :  null
                                    ))
                                }
                            </div>
                        </div>
                    </>
                    : <div>
                        <ul className={localStyles.broadcastReasonsSelected}>
                            {reasons?.length > 0 ? reasons?.map((reason) => (
                                    <li key={reason}>{reason}</li>
                                ))
                            : <p>No reasons selected</p>}
                        </ul>
                </div>
                }
            </div>
        </>
    )
};
