import React from "react";
import localStyles from "./BackSection.module.scss";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {Button} from "../inputs/button/Button";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";

type Props = {
  title?: string;
  displayButton?: boolean;
  displayBackButton?: boolean;
  textButton?: string;
  customWidth?: string;
  review?: string;
  onButtonClick?: () => void;
  onBackButtonClick?: () => void;
};

export const BackSection = ({
  title,
  displayButton,
  textButton,
  review,
  customWidth,
  onButtonClick,
  displayBackButton,
  onBackButtonClick,
}: Props):React.JSX.Element => {
  const broadcast = useSelector((state: RootState) => state.broadcast.broadcastCase);
  const isReview = useSelector((state: RootState) => state.broadcast.states.isReview);

  let displayMessage: string, displayClassSecondary = "";
  if (broadcast?.attributes?.is_active) {
    displayMessage = "Active";
    displayClassSecondary = localStyles.subTitleActive;
  };

  if (broadcast && !broadcast?.attributes?.is_active) {
    displayMessage = "Inactive";
    displayClassSecondary = localStyles.subTitleInactive;
  };

  return (
    <div style={{maxWidth: customWidth}}>
      {displayBackButton ? (
        <span className={localStyles.backButton} onClick={() => onBackButtonClick && onBackButtonClick()}>
        <ArrowLeftOutlined className={localStyles.arrowIcon} />
        Back
      </span>
      ) : null}
      <div className={localStyles.titleContainer}>
        <span className={localStyles.title}>{title}
          {isReview ? review : null}
          {broadcast ? " - " : ""}
          <span className={displayClassSecondary}>{displayMessage}</span>
        </span>
        {displayButton ? <Button onClick={onButtonClick && onButtonClick} text={textButton} /> : null}
      </div>
    </div>
  );
};
