import {ConsumerSegmentationType} from "../../store/broadcastSlice";
import {RequiredInputs} from "../../types/general";

export function validateInputs(inputs: RequiredInputs): { message: string, notValid: string} | null {
    for (const [fieldName, fieldValue] of Object.entries(inputs)) {
        const inputName = fieldName.split("_").join(" ").toUpperCase();
        if (Array.isArray(fieldValue) && fieldValue.length === 0) {
            return {
              message: `The field "${inputName}" is empty. You must add at least one.`,
              notValid: fieldName
            };
        }

        if (!fieldValue) {
            return {
              message: `The field "${inputName}" is empty. You must fill all the required fields.`,
              notValid: fieldName
            };
        }
    }

    return null;
};

export function validateConsumerSelected(guestInHome: boolean, upcomingArrivals: boolean, newBooking: boolean): boolean {
    return !guestInHome && !upcomingArrivals && !newBooking;
};

export function validateConsumersFields(consumer: ConsumerSegmentationType): string | null {
    if (consumer.email.checked && (consumer.email.message === "" || consumer.email.subject === "")) {
        return "You must fill the message and subject EMAIL";
    };
    if (consumer.airbnbChat.checked && consumer.airbnbChat.message === "") {
        return "You must fill the AIRBNB message";
    }
    if (consumer.smsText.checked && consumer.smsText.message === "") {
        return "You must fill the SMS message";
    }

    return null;
};


export function validateUnitCodesFormat(input: string): boolean {
    if (input.trim().length === 0) return false;
    // Regular expression to validate the string
    const regex = /^[a-zA-Z0-9, -]+$/;
    return regex.test(input);
}
