"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoggingService = void 0;
require("reflect-metadata");
const inversify_1 = require("inversify");
const logger_1 = require("@vacasa/logger");
const container = new inversify_1.Container();
container.bind(logger_1.LoggingService.getId()).to(logger_1.LoggingService);
const loggingService = container.get(logger_1.LoggingService.getId());
exports.LoggingService = loggingService;
