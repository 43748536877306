/// <reference types="node" />
export class FrontendConfiguration {
    static readonly LOCAL: string = "local";
    static readonly STAGING: string = "staging";
    static readonly DEVELOPMENT: string = "development";
    static readonly PRODUCTION: string = "production";

    static readonly DD_VACASA_BROADCAST_SERVICE: string = "vacasa-broadcast-service";

    public static environment(): string {
        if (FrontendConfiguration.appUrl().includes("localhost")) {
            return this.LOCAL;
        } else if (FrontendConfiguration.appUrl().includes("vacasastage.")) {
            return this.STAGING;
        } else if (FrontendConfiguration.appUrl().includes("vacasadev.")) {
            return this.DEVELOPMENT;
        } else if (FrontendConfiguration.appUrl().includes("vacasa.")) {
            return this.PRODUCTION;
        }

        return this.LOCAL;
    }

    public static appUrl(): string {
        const {REACT_APP_SPA_URL} = process.env;
        return REACT_APP_SPA_URL;
    }

    public static dataDogUrl(): string {
        const {REACT_APP_DD_URL} = process.env;
        return REACT_APP_DD_URL;
    }

    public static adminConfig(): any {
        const {REACT_APP_ADMIN_PUBLIC_KEY, REACT_APP_ADMIN_URL} = process.env;
        return {publicKey: REACT_APP_ADMIN_PUBLIC_KEY, url: REACT_APP_ADMIN_URL};
    }

    public static vacasaBroadcastServiceConfig() {
        const {REACT_APP_VBS_API_URL} = process.env;
        return {url: REACT_APP_VBS_API_URL};
    }

    public static IdpServiceConfig() {
        const {REACT_APP_IDP_URL} = process.env;
        return {url: REACT_APP_IDP_URL};
    }

    public static implicitFlowConfig(): any {
        const {REACT_APP_IDP_CLIENT_ID, REACT_APP_SPA_IDP_AUDIENCE, REACT_APP_SPA_IDP_SCOPES, REACT_APP_IDP_REDIRECT_URI} = process.env;
        let environment;
        switch (this.environment()) {
            case "staging":
                environment = "stage";
                break;
            case "production":
                environment = "prod";
                break;
            default:
                environment = "stage";
                break;
        }
        return {
            clientId: REACT_APP_IDP_CLIENT_ID,
            audience: REACT_APP_SPA_IDP_AUDIENCE,
            scopes: REACT_APP_SPA_IDP_SCOPES,
            directoryHint: "onelogin",
            env: environment,
            redirectUrl: REACT_APP_IDP_REDIRECT_URI,
            tokenRefreshRate: 1000 * 60 * 2,
        };
    }

    public static datadogGlobalContext() {
        return {
            env: this.environment(),
            host: this.appUrl(),
            division: "platform",
            project: "platform-tools",
            repository: "vacasa-broadcast-service",
            team: "chatterbox-mimes",
        };
    }

    public static datadogCustomGlobalContext(service: string) {
        return {service, ...this.datadogGlobalContext()};
    }

    public static getSentryDsn() {
        const {REACT_APP_SENTRY_DSN} = process.env;
        return REACT_APP_SENTRY_DSN;
    }

    public static getDataDogToken(): string {
        const {REACT_APP_DATADOG_TOKEN} = process.env;
        return REACT_APP_DATADOG_TOKEN;
    }

    public static getAdminUrl() {
        const {REACT_APP_ADMIN_URL} = process.env;
        return REACT_APP_ADMIN_URL;
    }

}
