import React, {useEffect, useState} from "react";
import {Table} from "antd";
import {RootState, useAppDispatch} from "../../store/store";
import {useSelector} from "react-redux";
import {TableColumns} from "../../types/general";
import {transformData} from "../../utils/general/General";
import {getBroadcasts, updateTablePagination} from "../../store/queueSlice";
import {useQueueSearchParams} from "../../hooks/useQueueSearchParams";
import {updateQueryFilters, resetFilters} from "../../store/queueSlice";
import {useNavigate} from "react-router-dom";

export const QueueTable = (): React.JSX.Element => {

    // RTK State
    const dispatch = useAppDispatch();
    const fetching = useSelector((state: RootState) => state.queue.isFetching);
    const queueData = useSelector((state: RootState) => state.queue.data);
    const filterByTitle = useSelector((state: RootState) => state.queue.filters.title.value);
    const filterByIsActive = useSelector((state: RootState) => state.queue.filters.is_active.value);
    const filterByPageNumber = useSelector((state: RootState) => state.queue.filters.pageNumber.value) as number;
    const filterByPageSize = useSelector((state: RootState) => state.queue.filters.pageSize.value) as number;
    const count = useSelector((state: RootState) => state.queue.pagination.count);
    const navigate = useNavigate();

    // Filters
    const {
        isQueryFilters,
        title,
        is_active,
        pageNumber,
        pageSize,
        setSearchParamsSync,
    } = useQueueSearchParams();

    // Table Columns
    const BROADCASTS_GRID_COLUMNS: TableColumns[] = [
        {
            title: 'Unit Code(s)',
            dataIndex: 'unitCodes',
            key: 'unitCodes'
        },
        {
            title: 'Start Date',
            dataIndex: 'startDate',
            key: 'startDate',
            sorter: (a, b) => new Date(a.startDate).valueOf() - new Date(b.startDate).valueOf()
        },
        {
            title: 'End Date',
            dataIndex: 'endDate',
            key: 'endDate',
            sorter: (a, b) => new Date(a.endDate).valueOf() - new Date(b.endDate).valueOf()
        },
        {
            title: 'Created By',
            key: 'createdBy',
            dataIndex: 'createdBy',
        },
        {
            title: 'Name of Incident',
            key: 'incidentName',
            dataIndex: 'incidentName',
        },
        {
            title: '',
            key: 'broadcastDetails',
            dataIndex: 'broadcastDetails',
            onCell: (record) => {
                return {
                    onClick: () => {
                        navigate(`/broadcast_case/${record?.key}`);
                    },
                };
            },
        },
    ];

    // Find the index of the 'Created By' column
    const createdByIndex = BROADCASTS_GRID_COLUMNS.findIndex(column => column.dataIndex === 'createdBy');

    // Create a new constant array by spreading the original columns and inserting the new object
    const BROADCASTS_GRID_COLUMNS_WITH_DEACTIVATE_BY: TableColumns[] = [
        ...BROADCASTS_GRID_COLUMNS.slice(0, createdByIndex + 1),
        {
            title: 'Deactivated By',
            key: 'deactivatedBy',
            dataIndex: 'deactivatedBy',
        },
        ...BROADCASTS_GRID_COLUMNS.slice(createdByIndex + 1),
    ];

    // Local State
    const data = transformData(queueData);
    const [tableColumns, setTableColumns] = useState<TableColumns[]>(BROADCASTS_GRID_COLUMNS);

    // Handlers
    const handlePaginationChange = (paginationData: any) => {
        const {current, pageSize} = paginationData;
        dispatch(updateTablePagination({pageNumber: current, pageSize}));
        dispatch(updateQueryFilters({title: filterByTitle, is_active: filterByIsActive, pageNumber: current, pageSize}));
    };

    const handlePageSizeChange = (current: number, size: number) => {
        dispatch(updateTablePagination({pageNumber: current, pageSize: size}));
        dispatch(updateQueryFilters({title: filterByTitle, is_active: filterByIsActive, pageNumber: current, pageSize: size}));
    };

    // Effects
    // On initial load compare QueryFilters with Default Filters
    useEffect(() => {
        // If no query param filters then fetch default data
        if (!isQueryFilters) {
            const fetchData = async () => await dispatch(getBroadcasts());
            fetchData();
            return;
        }

        // If query param filters then update filters to trigger useEffect
        dispatch(updateQueryFilters({title, is_active, pageNumber, pageSize}));
        return () => {dispatch(resetFilters())};
    }, []);

    useEffect(() => {
        setSearchParamsSync(filterByTitle, "title");
        setSearchParamsSync(filterByIsActive, "is_active");
        setSearchParamsSync(filterByPageNumber, "pageNumber");
        setSearchParamsSync(filterByPageSize, "pageSize");
        const fetchData = async () => await dispatch(getBroadcasts());
        fetchData();
    }, [filterByTitle, filterByIsActive, filterByPageNumber, filterByPageSize]);

    useEffect(() => {
        if (!filterByIsActive) {
            setTableColumns(BROADCASTS_GRID_COLUMNS_WITH_DEACTIVATE_BY);
        } else {
            setTableColumns(BROADCASTS_GRID_COLUMNS);
        }
    }, [filterByIsActive]);

    return (
        <Table
            loading={fetching}
            columns={tableColumns as any}
            dataSource={data}
            onChange={handlePaginationChange}
            pagination={{
                pageSize: filterByPageSize,
                pageSizeOptions: ["20", "50", "100"],
                total: count,
                current: filterByPageNumber,
                onShowSizeChange: handlePageSizeChange,
            }}
        />
    )
};
