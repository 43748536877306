import React from "react";
import localStyles from "./MessageType.module.scss";
import {Checkbox} from "antd";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../store/store";
import {FormOutlined} from "@ant-design/icons";
import {setIsReview} from "../../store/broadcastSlice";
import type {CheckboxChangeEvent} from "antd/es/checkbox";

type Props = {
    title?: string | React.ReactNode;
    subTitle?: string | React.ReactNode;
    checkboxMessage?: string;
    checked?: boolean;
    message?: string;
    readOnly: boolean;
    hideTextInput?: boolean;
    onInputChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => {};
    onCheckboxChange?: (event: CheckboxChangeEvent) => void;
};

export const MessageType = ({title, subTitle, checkboxMessage, checked, message, readOnly, hideTextInput, onInputChange, onCheckboxChange}: Props): React.JSX.Element => {
    //RTK State
    const dispatch = useAppDispatch();
    const isReview = useSelector((state: RootState) => state.broadcast.states.isReview);

    return (
        <div className={localStyles.titleContainer}>
            <div className="review-container">
                <span className="broadcast-section-title">{title}</span>
                {isReview && !readOnly  ? <FormOutlined onClick={() => dispatch(setIsReview(false))} className="edit-icon" /> : null}
            </div>
            {subTitle && !readOnly  && <p className={localStyles.subTitle}>{subTitle}</p>}
            <>
                {!readOnly? <div className={localStyles.checkboxContainer}>
                    <Checkbox disabled={isReview || readOnly} checked={checked} className={isReview || readOnly ? "checkboxDisabled" : "checkbox"} onChange={onCheckboxChange} />
                    <span className={localStyles.checkboxMessage}>{checkboxMessage}</span>
                </div>: null}
                { (isReview || readOnly) && !checked? <p className={localStyles.messageText}>N/A</p> : (!hideTextInput && <p className={localStyles.messageText}>Message</p>)}
                { isReview || readOnly ? <span className={localStyles.subTitle}>{message}</span> : (!hideTextInput && <textarea className={localStyles.messageInput} defaultValue={message || ""} onChange={onInputChange} />)}
            </>
        </div>
    )
};
