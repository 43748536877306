import React, {useEffect} from "react";
import {BrowserRouter as Router, Route, Routes, Navigate} from "react-router-dom";
import {QueueHeader} from "./components/queue-header/QueueHeader";
import {APP_ROUTES} from "./routes/Routes";
import {BroadcastView} from "./views/broadcast/BroadcastView";
import {OptimizelyFeature, setLogger} from "@optimizely/react-sdk";
import {getUserInfo} from "./utils/user-info/UserInfo";
import {Alert} from "antd";

export const App = (): React.JSX.Element => {
  return (
    <div className="vacasa-broadcast-service">
      <OptimizelyFeature feature="vbs_spa">
          {(isEnabled, variables) => (isEnabled && variables.USERS_ALLOWED.includes(getUserInfo()?.email)) ?
          <Router>
            <Routes>
              <Route path="/" element={<Navigate to="queue" />} />
              <Route path={"broadcast_case/:id"} element={<BroadcastView readOnly={true} />} />
              <Route path={"queue"} element={<QueueHeader />}>
                {APP_ROUTES.map((route, index) => (
                  <Route key={index} path={route.path} element={route.element} />
                ))}
              </Route>
              <Route path={"submission_form"} element={<BroadcastView readOnly={false} />} />
            </Routes>
          </Router> : 
          <Alert
            message="Access Restricted"
            description="We're sorry, but you don't have permission to access to this page. If you believe this is an error, please contact Product/Administration for assistance. Thank you for your understanding."
            type="info"
            showIcon
          />}
      </OptimizelyFeature>
    </div>
  );
};
