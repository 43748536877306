import React from "react";
import {FilterSearch} from "../filter-search/FilterSearch";
import localStyles from "./QueueHeader.module.scss";
import {Outlet} from "react-router";
import {RootState, useAppDispatch} from "../../store/store";
import {updateIsActiveFilter, updateQueryFilters, updateTablePagination} from "../../store/queueSlice";
import {useSelector} from "react-redux";
import {BroadcastErrorModal} from "../modals/Modals";
import {BackSection} from "../back-section/BackSection";
import {useNavigate} from "react-router-dom";
import {setIsReview} from "../../store/broadcastSlice";

export const QueueHeader = (): React.JSX.Element => {
    //RTK State
    const dispatch = useAppDispatch();
    const filterByIsActive = useSelector((state: RootState) => state.queue.filters.is_active.value);
    const filterByTitle = useSelector((state: RootState) => state.queue.filters.title.value);
    const filterByPageSize = useSelector((state: RootState) => state.queue.filters.pageSize.value) as number;
    const isFetching = useSelector((state: RootState) => state.queue.isFetching);
    const navigate = useNavigate();

    //Handlers
    const handleTabChange = (status: boolean) => {
        dispatch(updateQueryFilters({title: filterByTitle, is_active: status, pageNumber: 1, pageSize: filterByPageSize}));
        dispatch(updateTablePagination({pageNumber: 1, pageSize: filterByPageSize}));
        dispatch(updateIsActiveFilter(status));
    };

    const handleCreateIncident = () => {
        navigate('/submission_form');
    };

    return (
        <>
            <BackSection
                title="Vacasa Broadcast Service"
                textButton="Create Incident"
                displayBackButton={false}
                onButtonClick={handleCreateIncident}
                displayButton
                />
            <div className={localStyles.container}>
                <div className={localStyles.inner}>
                    <div className={localStyles.title}>
                        {filterByIsActive ? "Active Broadcasts" : "Deactivated Broadcasts"}
                    </div>
                    <div className={localStyles.subHeader}>
                        <div className={localStyles.filters}>
                            <FilterSearch placeholder="Search" filterName="title" />
                        </div>
                        <div className={localStyles.linkContainer}>
                            <button disabled={isFetching} onClick={() => handleTabChange(true)} className={filterByIsActive ? localStyles.activeLink : ""}>
                                Active
                            </button>
                            <button disabled={isFetching} onClick={() => handleTabChange(false)} className={!filterByIsActive ? localStyles.activeLink : ""}>
                                Deactivated
                            </button>
                        </div>
                    </div>
                    <div className={localStyles.tableContainer}>
                        <Outlet />
                    </div>
                </div>
            </div>

            {/* Modals */}
            <BroadcastErrorModal />
        </>
    )
};
