import React, {useEffect} from "react";
import ReactDOM from "react-dom/client";
import {App} from "./App";
import "./index.scss";
import {Provider} from "react-redux";
import {store} from "./store/store";
import {ImplicitFlow, ThemeProvider} from "@vacasa/react-components-lib";
import DefaultTheme from "@vacasa/react-components-lib/src/themes/default";
import {FrontendConfiguration} from "@common/configuration";
import {OPTIMIZELY_SDK_KEY} from "./config/optimizely";
import {nanoid} from "nanoid";
import {createInstance, OptimizelyProvider, setLogger, withOptimizely} from "@optimizely/react-sdk";
import {setFavicon} from "./config/global.config";
import {Monitoring} from "./config/monitoring.config";

const optimizely: any = createInstance({
    sdkKey: OPTIMIZELY_SDK_KEY,
    eventBatchSize: 10,
    eventFlushInterval: 1000
});

// Initiate Sentry && Datadog (for Stage and Prod)
Monitoring.init();

// Set Favicon
setFavicon();

const OptimizelyRootApp = () => {
    const AppWrapped = withOptimizely(App);

    // Remove the console.log()'s from optimizely
    useEffect(() => {
        setLogger(null);
    }, []);

    return (
        <OptimizelyProvider optimizely={optimizely} user={{id: nanoid()}}>
            <AppWrapped />
        </OptimizelyProvider>
    );
};

ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        <ThemeProvider theme={DefaultTheme}>
            <ImplicitFlow {...FrontendConfiguration.implicitFlowConfig()} followRedirect={true}>
                <Provider store={store}>
                    <OptimizelyRootApp />
                </Provider>
            </ImplicitFlow>
        </ThemeProvider>
    </React.StrictMode>,
);
