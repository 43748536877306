"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VacasaBroadcastService = void 0;
const configuration_1 = require("@common/configuration");
const implicit_flow_service_1 = require("./base/implicit-flow.service");
const setup_1 = require("../../setup");
class VacasaBroadcastService extends implicit_flow_service_1.ImplicitFlowService {
    constructor() {
        super(configuration_1.FrontendConfiguration.vacasaBroadcastServiceConfig().url);
        this.logger = setup_1.LoggingService === null || setup_1.LoggingService === void 0 ? void 0 : setup_1.LoggingService.getLogger("VacasaBroadcastService");
    }
    static getInstance() {
        if (!VacasaBroadcastService.service) {
            VacasaBroadcastService.service = new VacasaBroadcastService();
        }
        return VacasaBroadcastService.service;
    }
    /**
     * Creates a new broadcast case
     * @param {Partial<BroadcastCreated>} payload
     * @returns {Promise<GetListResponse<BroadcastCreated>>}
     */
    async createBroadcast(payload) {
        try {
            const axios = await this.axiosInstance(1000, 1);
            const response = await axios.post(`${this.apiUrl}/api/v1/broadcast`, payload);
            return response.data;
        }
        catch (e) {
            throw e === null || e === void 0 ? void 0 : e.response;
        }
    }
    /**
     * get a detailed broadcast case
     * @param id
     * @returns
     */
    async getBroadcastById(id) {
        try {
            const axios = await this.axiosInstance();
            const params = {
                "include": "units",
            };
            const { data: response } = await axios.get(`${this.apiUrl}/api/v1/broadcast/${id}`, { params });
            return response;
        }
        catch (e) {
            this.logger.error("getBroadcastById error", e);
            throw e;
        }
    }
    /**
     * Returns a list of the broadcast reasons
     * @returns {Promise<GetListResponse<BroadcastReason>>}
     */
    async getReasons() {
        try {
            const axios = await this.axiosInstance();
            const { data } = await axios.get(`${this.apiUrl}/api/v1/broadcast-reason`);
            return data;
        }
        catch (e) {
            throw e === null || e === void 0 ? void 0 : e.response;
        }
    }
    /**
     * Returns a list of the broadcasts based on the filters
     * @param filters FiltersItem
     * @returns {Promise<GetListResponse<Broadcast>>}
     */
    async getBroadcasts(filters) {
        try {
            const { title, is_active, pageNumber, pageSize } = filters;
            const params = {
                "include": "units",
                "filter[is_active]": is_active,
                "sort": "-created_at",
                "page[number]": pageNumber,
                "page[size]": pageSize,
                ...(title && { "filter[title][icontains]": title }),
            };
            const axios = await this.axiosInstance();
            const { data } = await axios.get(`${this.apiUrl}/api/v1/broadcast`, { params });
            return data;
        }
        catch (e) {
            throw e === null || e === void 0 ? void 0 : e.response;
        }
    }
    /**
     * Call the endpoint to disable a broadcast
     * @param id
     * @param payload
     * @returns
     */
    async disableBroadcast(id, payload) {
        try {
            const data = {
                type: "broadcast",
                attributes: payload,
            };
            const axios = await this.axiosInstance(1000, 1);
            const response = await axios.put(`${this.apiUrl}/api/v1/disable-broadcast/${id}`, { data });
            return response.data;
        }
        catch (e) {
            throw e;
        }
    }
    /**
     * Validate a list of unit codes
     * @param {ValidateUnitsData} data
     * @returns {Promise<ValidateUnitResponse>}
     */
    async validateUnit(data) {
        try {
            const axios = await this.axiosInstance();
            const response = await axios.post(`${this.apiUrl}/api/v1/validate-units`, data);
            return response === null || response === void 0 ? void 0 : response.data;
        }
        catch (e) {
            throw e === null || e === void 0 ? void 0 : e.response;
        }
    }
}
exports.VacasaBroadcastService = VacasaBroadcastService;
