import {SecretsManager} from "@vacasa/secrets-manager";
import {VarEnvValidator} from "@vacasa/core";

export class BackendConfiguration {
    private static readonly awsSecret: SecretsManager = new SecretsManager("process.env.STAGE_ENV");
    private static secret;

    static getEventHubUrl(): string {
        return process.env.VACASA_EVENTHUB_URL || '';
    }

    static getEventHubProductLine(): string {
        return process.env.EVENTHUB_PRODUCT_LINE;
    }

    static getEventHubPublisherAppName(): string {
        return process.env.EVENTHUB_PUBLISHER_APPNAME;
    }

    public static getRedisConfiguration() {
        return {
            host: process.env.REDIS_HOST,
            port: process.env.REDIS_PORT as unknown as number,
            keyPrefix: `${process.env.AWS_LAMBDA_FUNCTION_NAME}:`,
            lazyConnect: true,
            maxRetriesPerRequest: 0,
            enableReadyCheck: false,
        };
    }

    public static getSentryConfiguration(): {dsn: string, environment: string, serverName: string, tracesSampleRate: number}{
        return {
            dsn: process.env.SENTRY_DSN,
            environment: process.env.ENVIRONMENT,
            serverName: process.env.IDP_SERVER_AUDIENCE,
            tracesSampleRate: 0.01,
        };
    }

    public static getIdpServerConfig() {
        return {
            domain: process.env.IDP_SERVER_DOMAIN,
            audience: process.env.IDP_SERVER_AUDIENCE?.split(","),
            disabled: process.env.VACASA_IDP_API_DISABLE == "true",
        };
    }

    public static async getIdpClientConfig(): Promise<any> {
        if (!process.env.IDP_CLIENT_ID) {
            this.secret = JSON.parse(await this.awsSecret.getSecret("vacasa-broadcast-service/backend/idp-credentials"));
        } else {
            this.secret = {
                client_id: process.env.IDP_CLIENT_ID,
                client_secret: process.env.IDP_SECRET_ID,
            };
        }

        return {
            clientId: this.secret.client_id,
            clientSecret: this.secret.client_secret,
            url: `${BackendConfiguration.getIdpServerConfig().domain}/token`,
        };
    }

    public static sharedServiceConfig(): {audience: string, scope: string[]} {
        return {
            audience:[
                process.env.UNIT_SERVICE_AUDIENCE,
            ].filter(Boolean).join(" ").trim(),
            scope: [...new Set([
                process.env.UNIT_SERVICE_SCOPES,
            ].filter(Boolean).map(e => e.trim()).join(" ").split(" "))]
        }
    }

    public static getCronArn(cronName: string): string {
        return VarEnvValidator.getEnvVar(cronName);
    }

}
