import {useSearchParams} from "react-router-dom";
import {defaultFilters} from "../constants/constants";

export const useQueueSearchParams = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const setSearchParamsSync = (value: any, key: any) => {
        if (value === defaultFilters[key]) {
            searchParams.delete(key);
            setSearchParams(searchParams);
        } else {
            searchParams.set(key, value);
            setSearchParams(searchParams);
        }
    };

    const title = searchParams.get("title") || defaultFilters.title;
    const is_active = (searchParams?.get("is_active")?.toLowerCase() === 'true') || defaultFilters.is_active;
    const pageNumber = +(searchParams?.get("pageNumber")?.toLowerCase() === 'true' || 0) || defaultFilters.pageNumber;
    const pageSize = +(searchParams?.get("pageSize")?.toLowerCase() === 'true' || 0) || defaultFilters.pageSize;

    const isQueryFilters = [
        title !== defaultFilters.title,
        is_active !== defaultFilters.is_active,
        pageNumber !== defaultFilters.pageNumber,
        pageSize !== defaultFilters.pageSize,
    ].some(Boolean);

    return {
        isQueryFilters,
        title,
        is_active,
        pageNumber,
        pageSize,
        setSearchParamsSync,
    };
};
