"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Service = void 0;
const _ = require("lodash");
class Service {
    buildFilter(filter) {
        const parsedFilter = {};
        if (_.isNil(filter)) {
            return parsedFilter;
        }
        const keys = _.keys(filter);
        for (let key of keys) {
            parsedFilter[`filter[${key}]`] = filter[key];
        }
        return parsedFilter;
    }
}
exports.Service = Service;
