export type RequiredInputs = {
    title_of_incident: string;
    starts_on: string;
    ends_on: string;
    impacted_units: Array<{id: number, code: string}>;
}

export type TableColumns = {
    title: string;
    dataIndex: string;
    key: string;
    sorter?: (value: any, nextValue: any) => boolean | number;
    onCell?: (record: any) => void;
}

export const ValidationInputs = {
    title_of_incident: "title_of_incident",
    starts_on: "starts_on",
    ends_on: "ends_on",
    impacted_units: "impacted_units",
    internal_communication: "internal_communication",
    channel_communication: "channel_communication",
    embed_communication: "embed_communication",
    consumer_segmentation: "consumer_segmentation"
}

export type ValidationInputsType = keyof typeof ValidationInputs;
