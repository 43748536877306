import React, {useEffect} from "react";
import localStyles from "./DatesEffective.module.scss";
import {DatePicker} from "antd";
import {Dayjs} from "dayjs";
import dayjs from "dayjs";
import {RootState, useAppDispatch} from "../../store/store";
import {setEndDate, setIsReview, setStartDate} from "../../store/broadcastSlice";
import {useSelector} from "react-redux";
import {FormOutlined} from "@ant-design/icons";
import {ReadOnlyProps} from "../../constants";

type DatePickerLocalProps = {
    label?: string;
    defaultValue?: string;
    disabled?: boolean;
    disabledDate?: (date: Dayjs) => boolean;
    onChange?: (date: Dayjs, dateString: string) => void;
};

const DatePickerLocal = ({label, defaultValue, disabled, disabledDate, onChange}: DatePickerLocalProps) => {
    const newDate = dayjs(defaultValue);

    return (
        <div className={localStyles.datePicker}>
            <label className={localStyles.dateLabel}>{label}</label>
            <DatePicker
                className={localStyles.inputDate}
                value={defaultValue === "" ? null : newDate}
                disabledDate={disabledDate}
                disabled={disabled}
                format={"YYYY-MM-DD"}
                placeholder={"yyyy-mm-dd"}
                onChange={onChange}
            />
        </div>
    );
};

export const DatesEffective = ({ readOnly }: ReadOnlyProps): React.JSX.Element => {
    //RTK State
    const dispatch = useAppDispatch();
    const isReview = useSelector((state: RootState) => state.broadcast.states.isReview);
    let startDate = useSelector((state: RootState) => state.broadcast.data.form.startDate);
    let endDate = useSelector((state: RootState) => state.broadcast.data.form.endDate);
    const broadcast = useSelector((state: RootState) => state.broadcast.broadcastCase);

    //Handlers
    const handleEndDateChange = (date: Dayjs, dateString: string) => {
        dispatch(setEndDate(dateString));
    };

    const handleStartDateChange = (date: Dayjs, dateString: string) => {
        dispatch(setStartDate(dateString));
    };

    //Store broadcast detail dates
    useEffect(() => {
        if(broadcast){
            dispatch(setStartDate(broadcast.attributes.start_date));
            dispatch(setEndDate(endDate = broadcast.attributes.end_date));
        }
    }, [broadcast]);

    return (
        <>
            <div className="review-container">
                <span className="broadcast-section-title">Dates Effective {!isReview && !readOnly && <span className="required-field">(*)</span>}</span>
                {isReview ? <FormOutlined onClick={() => dispatch(setIsReview(false))} className="edit-icon" /> : null}
            </div>
            <div className={localStyles.impactedUnitsContainer}>
                {!isReview && !readOnly
                    ? <div className={localStyles.datePickersContainer}>
                        <DatePickerLocal
                            disabled={false}
                            defaultValue={startDate}
                            label="Starts on:"
                            disabledDate={(current) => current && current < dayjs().startOf("day")}
                            onChange={handleStartDateChange}
                        />
                        <DatePickerLocal
                            disabled={startDate === ""}
                            defaultValue={endDate}
                            label="Ends on:"
                            disabledDate={(current) =>
                                current && (current < dayjs(startDate).startOf("day") || current > dayjs(startDate).add(4, "months").startOf("day"))
                            }
                            onChange={handleEndDateChange}
                        />
                    </div>
                    : <div className={localStyles.datesContainer}>
                        <span className={localStyles.reviewDates}>{startDate}</span>
                        <span className={localStyles.toDates}> to </span>
                        <span className={localStyles.reviewDates}>{endDate}</span>
                      </div>
                }
            </div>
        </>
    )
};
