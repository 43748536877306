import React from "react";
import localStyles from "./IncidentTitle.module.scss";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../store/store";
import {setIncidentTitle, setIsReview} from "../../store/broadcastSlice";
import {FormOutlined} from "@ant-design/icons";
import {ReadOnlyProps} from "../../constants";

export const IncidentTitle = ({ readOnly }: ReadOnlyProps): React.JSX.Element => {
    //RTK State
    const dispatch = useAppDispatch();
    const title = useSelector((state: RootState) => state.broadcast.data.form.incidentTitle);
    const isReview = useSelector((state: RootState) => state.broadcast.states.isReview);
    const broadcast = useSelector((state: RootState) => state.broadcast.broadcastCase);

    //Handlers
    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(e?.target?.value.length > 256) return;
        dispatch(setIncidentTitle((e?.target?.value)));
    };

    return (
        <div className={localStyles.titleContainer}>
            <div className="review-container">
                <span className="broadcast-section-title">Title of Incident {!isReview && !readOnly && <span className="required-field">(*)</span>}</span>
                {isReview ? <FormOutlined onClick={() => dispatch(setIsReview(false))} className="edit-icon" /> : null}
            </div>
            {readOnly ?
                <span className={localStyles.spanTitle}>{broadcast?.attributes?.title}</span>:
                isReview
                    ? <span className={localStyles.spanTitle}>{title}</span>
                    : <input className={localStyles.inputTitle} value={title} type="text" onChange={handleTitleChange}/>
            }
        </div>
    )
};
