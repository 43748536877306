"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientCredentialService = void 0;
const idp_1 = require("@vacasa/idp");
const setup_1 = require("../../../setup");
const configuration_1 = require("@common/configuration");
const service_base_1 = require("../../base/service.base");
const _ = require("lodash");
class ClientCredentialService extends service_base_1.Service {
    constructor(serviceApiConfig) {
        super();
        this.serviceApiConfig = serviceApiConfig;
        this.IdpAxiosClient = null;
        this.logger = setup_1.LoggingService === null || setup_1.LoggingService === void 0 ? void 0 : setup_1.LoggingService.getLogger("ClientCredentialService");
    }
    get apiUrl() {
        // TODO: Deprecate
        return "";
    }
    static async getInstance() {
        if (ClientCredentialService._instance)
            return ClientCredentialService._instance;
        ClientCredentialService._instance = new ClientCredentialService(configuration_1.BackendConfiguration.sharedServiceConfig());
        await ClientCredentialService._instance.axiosInstance();
        return ClientCredentialService._instance;
    }
    static async getAxiosInstance() {
        return await (await ClientCredentialService.getInstance()).axiosInstance();
    }
    async axiosInstance(backoff = 1000, maxAttempts = 3) {
        if (_.isNil(this.IdpAxiosClient)) {
            const conf = await configuration_1.BackendConfiguration.getIdpClientConfig();
            this.IdpAxiosClient = new idp_1.IdpAxiosClient(conf, this.serviceApiConfig, setup_1.LoggingService);
        }
        // @ts-ignore
        return await this.IdpAxiosClient.authInstance(backoff, maxAttempts);
    }
}
exports.ClientCredentialService = ClientCredentialService;
