"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnitService = void 0;
const client_credentials_service_1 = require("./base/client-credentials.service");
const setup_1 = require("../../setup");
class UnitService {
    constructor() {
        this.apiUrl = process.env.UNIT_SERVICE_URL;
        this.logger = setup_1.LoggingService === null || setup_1.LoggingService === void 0 ? void 0 : setup_1.LoggingService.getLogger("UnitService");
    }
    static getInstance() {
        if (!UnitService.service) {
            UnitService.service = new UnitService();
        }
        return UnitService.service;
    }
    /**
     * Gets Unit
     * @param unitCode unit code
     * @returns
     */
    async getUnitByUnitCode(unitCode) {
        try {
            const params = {
                "filter[unit_code]": unitCode,
            };
            const axios = await client_credentials_service_1.ClientCredentialService.getAxiosInstance();
            const response = await axios.get(`${this.apiUrl}/units`, { params });
            if (response.data.data && response.data.data.length > 0) {
                return response.data.data[0];
            }
            else {
                return null;
            }
        }
        catch (e) {
            this.logger.error("getUnitById error", e);
            throw e;
        }
    }
}
exports.UnitService = UnitService;
