import React from "react";
import {GridDataTransformedType} from "../../types/broadcast";
import {RightOutlined} from "@ant-design/icons";
import {Tooltip} from "antd";
import localStyles from "../../components/queue-table/QueueTable.module.scss";
import {Broadcast} from "@common/typing";

export function transformData(data: Broadcast[]): GridDataTransformedType[] {
    return data?.map((item) => {
        const unitsQuantity = item.attributes.units.length;
        const units = item.attributes.units;
        const dataTransformed: GridDataTransformedType = {
            key: item.id,
            unitCodes:
                unitsQuantity > 1 ? <Tooltip title={(units.map((unit, index) => `${unit.code}${index !== units.length - 1 ? ' , ' : ''}`))}>
                    {item.attributes.units[0].code} {unitsQuantity > 1 ? `(x${unitsQuantity - 1})` : ''}
                </Tooltip> : item.attributes.units[0].code,
            startDate: item.attributes.start_date,
            endDate: item.attributes.end_date,
            createdBy: item.attributes.created_by,
            incidentName:
                item.attributes.title.length > 30
                    ? <Tooltip title={item.attributes.title}>{cutString(item.attributes.title)}</Tooltip>
                    : item.attributes.title,
            broadcastDetails: <RightOutlined className={localStyles.broadcastDetailsIcon} />,
        };

        if (!item.attributes.is_active) {
            dataTransformed.deactivatedBy = item.attributes.disabled_by;
        }

        return dataTransformed;
    });
};

export const cutString = (value: string) => `${value.substring(0, 30)}...`;

export const splitString = (value: string, splitter: string = ",") => value.split(splitter).map((code) => {
    return code.trim();
});

/**
 * Use a regular expression to replace all characters that are not letters, numbers, or dash with an empty string
 * @param input string
 * @returns string
 */
export function clearSpecialCharacters(input: string): string {
    return input.replace(/[^a-zA-Z0-9-]/g, '');
};
