import React, {useEffect, useRef} from "react";
import localStyles from "./CommunicationType.module.scss";
import {Checkbox, Divider, Radio, Space, Tooltip, Alert} from "antd";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../store/store";
import {
    ConsumerSegmentationType,
    disableConsumerCheckboxes,
    setAirbnbMessage,
    setEmailMessage,
    setEmailSubject,
    setGuestInHomeConsumer,
    setIsReview,
    setNewBookingConsumer,
    setSmsMessage,
    setTypeOfRecipients,
    setUpcomingArrivalsConsumer
} from "../../store/broadcastSlice";
import {FormOutlined} from "@ant-design/icons";
import type {CheckboxChangeEvent} from "antd/es/checkbox";
import type {RadioChangeEvent} from "antd/es/radio";
import {CONSUMER_METHODS, CONSUMER_TYPES, DEFAULT_SMS_MESSAGE} from "../../constants/constants";
import {ReadOnlyProps} from "../../constants";

type ConsumerSegmentationProps = {
    title: string | React.ReactNode;
    subTitle: string | React.ReactNode;
    type?: string;
    consumerSegmentation?: ConsumerSegmentationType;
    readOnly: boolean,
    inputEvent?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    textareaEvent?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    checkboxEvent?: (e: CheckboxChangeEvent) => void;
    radioEvent?: (e: RadioChangeEvent) => void;
};

const ConsumerSegmentation = ({title, subTitle, consumerSegmentation, type, readOnly,  inputEvent, textareaEvent, checkboxEvent, radioEvent}: ConsumerSegmentationProps): React.JSX.Element => {
    //RTK State
    const dispatch = useAppDispatch();
    const isReview = useSelector((state: RootState) => state.broadcast.states.isReview);

    //Local variables and functions
    const disableMessage = "This method is disabled because you can't unselect all the methods.";

    const isEmailCheckboxDisabled = (): boolean => {
        return isReview || readOnly || consumerSegmentation.email.disabled;
    };

    const isAirBnbChatCheckboxDisabled = (): boolean => {
        return isReview || readOnly || consumerSegmentation.airbnbChat.disabled;
    };

    const isSmsTextCheckboxDisabled = (): boolean => {
        return isReview || readOnly || consumerSegmentation.smsText.disabled;
    };

    const EmailCheckboxWrapped = (): React.JSX.Element => {
        return (
            <Tooltip title={disableMessage}>
                <div>
                    <Checkbox
                        className={isEmailCheckboxDisabled() ? "checkboxDisabled" : "checkbox"}
                        disabled={isEmailCheckboxDisabled()}
                        name="email"
                        onChange={checkboxEvent}
                        checked={consumerSegmentation.email.checked}
                    >Email
                    </Checkbox>
                </div>
            </Tooltip>
        );
    };

    const AirBnbChatCheckboxWrapped = (): React.JSX.Element => {
        return (
            <Tooltip title={disableMessage}>
                <div>
                    <Checkbox
                        className={isAirBnbChatCheckboxDisabled() ? "checkboxDisabled" : "checkbox"}
                        disabled={isAirBnbChatCheckboxDisabled()}
                        name="airbnbChat"
                        onChange={checkboxEvent}
                        checked={consumerSegmentation.airbnbChat.checked}
                    >AirBnB Chat
                    </Checkbox>
                </div>
            </Tooltip>
        );
    };

    const SmsTextCheckboxWrapped = (): React.JSX.Element => {
        return (
            <Tooltip title={disableMessage}>
                <div>
                    <Checkbox
                        className={isSmsTextCheckboxDisabled() ? "checkboxDisabled" : "checkbox"}
                        disabled={isSmsTextCheckboxDisabled()}
                        name="smsText"
                        onChange={checkboxEvent}
                        checked={consumerSegmentation.smsText.checked}
                    >SMS Text
                    </Checkbox>
                </div>
            </Tooltip>
        );
    };

    const dividerClass = isReview || readOnly ? localStyles.divider : null;

    useEffect(() => {
        if (!consumerSegmentation.email.checked) return;
        switch (type) {
            case CONSUMER_TYPES.GUEST_IN_HOME:
                dispatch(setGuestInHomeConsumer({
                    ...consumerSegmentation,
                    email: {...consumerSegmentation.email},
                    airbnbChat: {...consumerSegmentation.airbnbChat, checked: true, disabled: consumerSegmentation.email.checked},
                    smsText: {...consumerSegmentation.smsText}
                }));
                break;
            case CONSUMER_TYPES.UPCOMING_ARRIVALS:
                dispatch(setUpcomingArrivalsConsumer({
                    ...consumerSegmentation,
                    email: {...consumerSegmentation.email},
                    airbnbChat: {...consumerSegmentation.airbnbChat, checked: true, disabled: consumerSegmentation.email.checked},
                    smsText: {...consumerSegmentation.smsText}
                }));
                break;
            case CONSUMER_TYPES.NEW_BOOKING:
                dispatch(setNewBookingConsumer({
                    ...consumerSegmentation,
                    email: {...consumerSegmentation.email},
                    airbnbChat: {...consumerSegmentation.airbnbChat, checked: true, disabled: consumerSegmentation.email.checked},
                    smsText: {...consumerSegmentation.smsText}
                }));
                break;
            default:
                break;
        }

    }, [consumerSegmentation.airbnbChat.checked, consumerSegmentation.email.checked, consumerSegmentation.smsText.checked]);

    return (
        <>
            <div className={localStyles.consumerSegmentationContainer}>
                <span className="broadcast-section-title">{title}</span>
                <span className={localStyles.subTitle}>{subTitle}</span>
                <div className={localStyles.content}>
                    <div id={`leftSection-${type}`} className={localStyles.leftSection}>
                        <span className={localStyles.sectionTitle}>Method</span>
                        {isEmailCheckboxDisabled() ? <EmailCheckboxWrapped /> :
                            <Checkbox
                                className={isEmailCheckboxDisabled() ? "checkboxDisabled" : "checkbox"}
                                disabled={isEmailCheckboxDisabled()}
                                name="email"
                                onChange={checkboxEvent}
                                checked={consumerSegmentation.email.checked}
                            >Email
                            </Checkbox>
                        }
                        {isAirBnbChatCheckboxDisabled() ? <AirBnbChatCheckboxWrapped /> :
                            <Checkbox
                                className={isAirBnbChatCheckboxDisabled() ? "checkboxDisabled" : "checkbox"}
                                disabled={isAirBnbChatCheckboxDisabled()}
                                name="airbnbChat"
                                onChange={checkboxEvent}
                                checked={consumerSegmentation.airbnbChat.checked}
                            >AirBnB Chat
                            </Checkbox>
                        }
                        {isSmsTextCheckboxDisabled() ? <SmsTextCheckboxWrapped /> :
                            <Checkbox
                                className={isSmsTextCheckboxDisabled() ? "checkboxDisabled" : "checkbox"}
                                disabled={isSmsTextCheckboxDisabled()}
                                name="smsText"
                                onChange={checkboxEvent}
                                checked={consumerSegmentation.smsText.checked}
                            >SMS Text
                            </Checkbox>
                        }

                        {/* Display SMS Text section if the option is checked */}
                        {consumerSegmentation.smsText.checked && !readOnly?
                            <div className={localStyles.radioSection}>
                                <Radio.Group onChange={radioEvent} name={type} value={consumerSegmentation.smsText.type}>
                                    <Space direction="vertical">
                                        <Radio
                                            className={isReview || readOnly ? "radioButtonDisabled" : "radioButton"}
                                            disabled={isReview || readOnly}
                                            value="default"
                                        >Default
                                        </Radio>
                                        <Radio
                                            className={isReview || readOnly ? "radioButtonDisabled" : "radioButton"}
                                            disabled={isReview || readOnly}
                                            value="custom"
                                        >Custom
                                        </Radio>
                                    </Space>
                                </Radio.Group>
                            </div> : null}
                    </div>
                    <div className={localStyles.rightSection}>
                        {consumerSegmentation.email.checked &&
                            <>
                                <span className={localStyles.sectionTitle}>Email</span>
                                <label className={localStyles.emailSubTitle}>Subject Line</label>
                                {isReview || readOnly ?
                                    <span className={localStyles.message}>{consumerSegmentation.email.subject}</span>
                                    :<>
                                        <input
                                            id={type}
                                            disabled={isReview || readOnly}
                                            defaultValue={consumerSegmentation.email.subject || ""}
                                            name="email-subject"
                                            className={localStyles.emailSubjectInput}
                                            type="text"
                                            placeholder="Start Typing..."
                                            onChange={inputEvent}
                                        />
                                        <span className={localStyles.messageAdvice}>Will not be visible in SMS, AirBnB chat, or Embed.</span>
                                    </>
                                }
                                
                                <label className={localStyles.emailSubTitle}>Message</label>
                                {isReview || readOnly ?
                                    <span className={localStyles.message}>{consumerSegmentation?.email?.message}</span>
                                    :<textarea
                                        id={type}
                                        defaultValue={consumerSegmentation?.email?.message || ""}
                                        disabled={isReview || readOnly}
                                        className={localStyles.emailMessageInput}
                                        placeholder="Start Typing..."
                                        name="emailMessage"
                                        onChange={textareaEvent}
                                    />
                                }
                            </>}
                        {consumerSegmentation.airbnbChat.checked &&
                            <>
                                {consumerSegmentation.email.checked && <Divider className={dividerClass}/>}
                                <label className={localStyles.sectionTitle}>AirBnB Chat</label>
                                {isReview || readOnly ?
                                    <span className={localStyles.message}>{consumerSegmentation?.airbnbChat?.message}</span>
                                    :<textarea
                                        id={type}
                                        defaultValue={consumerSegmentation?.airbnbChat?.message || ""}
                                        disabled={isReview || readOnly}
                                        className={localStyles.chatMessageInput}
                                        placeholder="Start Typing..."
                                        name="airBnb"
                                        onChange={textareaEvent}
                                    />
                                }
                                
                            </>}
                        {consumerSegmentation.smsText.checked &&
                            <>
                                {(consumerSegmentation.airbnbChat.checked || consumerSegmentation.email.checked) && <Divider className={dividerClass}/>}
                                <label className={localStyles.sectionTitle}>SMS Text - {consumerSegmentation.smsText.type === "default" ? "Default" : "Custom"}</label>
                                {isReview || readOnly ?
                                    <span className={localStyles.message}>{consumerSegmentation?.smsText?.message}</span>
                                    :<textarea
                                        id={type}
                                        disabled={isReview || readOnly || consumerSegmentation.smsText.type === "default"}
                                        className={localStyles.chatMessageInput}
                                        placeholder={DEFAULT_SMS_MESSAGE}
                                        value={consumerSegmentation?.smsText?.message}
                                        name="smsText"
                                        onChange={textareaEvent}
                                    />
                                }
                            </>}
                    </div>
                </div>
            </div>
        </>
    )
};

export const CommunicationType = ({ readOnly, consumerSegmentationError }: { readOnly: boolean, consumerSegmentationError: { message: string, consumer_segmentation: string } | null }): React.JSX.Element => {
    //RTK State
    const dispatch = useAppDispatch();
    const isReview = useSelector((state: RootState) => state.broadcast.states.isReview);
    const guestInHome = useSelector((state: RootState) => state.broadcast.data.form.guest_in_home);
    const upcomingArrivals = useSelector((state: RootState) => state.broadcast.data.form.upcoming_arrivals);
    const newBooking = useSelector((state: RootState) => state.broadcast.data.form.new_booking);

    const guestInHomeSegmentation = useSelector((state: RootState) => state.broadcast.data.form.consumerSegmentation.guest_in_home);
    const upcomingArrivalsSegmentation = useSelector((state: RootState) => state.broadcast.data.form.consumerSegmentation.upcoming_arrivals);
    const newBookingSegmentation = useSelector((state: RootState) => state.broadcast.data.form.consumerSegmentation.new_booking);

    const startDate = useSelector((state: RootState) => state.broadcast.data.form.startDate);
    const endDate = useSelector((state: RootState) => state.broadcast.data.form.endDate);

    const broadcast = useSelector((state: RootState) => state.broadcast.broadcastCase);

    const guestInHomeRef = useRef(null);
    const upcomingArrivalsRef = useRef(null);
    const newBookingRef = useRef(null);

    //Handlers
    const handleEmailInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const consumer = event.target.id;
        const value = event.target.value;
        dispatch(setEmailSubject({consumer, subject: value}));
    };

    const handleTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const consumer = event.target.id;
        const message = event.target.value;
        const method = event.target.name;

        const consumerActionMap: Record<string, Function> = {
            "emailMessage": setEmailMessage,
            "airBnb": setAirbnbMessage,
            "smsText": setSmsMessage,
        };

        const dispatchAction = consumerActionMap[method];
        if (dispatchAction) dispatch(dispatchAction({consumer, message}));
    };

    const handleCheckboxChange = (event: CheckboxChangeEvent) => {
        dispatch(setTypeOfRecipients({
            recipientType: event?.target?.name,
            checked: event?.target?.checked
        }));
    };

    const updateConsumerCheckboxesState = (type: string) => {
        const container = document.querySelector(`#leftSection-${type}`);
        const checkBoxesSelected = container.querySelectorAll('input[type="checkbox"]:checked');

        if (checkBoxesSelected.length > 1) {
            CONSUMER_METHODS.forEach(method => {
                dispatch(disableConsumerCheckboxes({
                    consumer: type,
                    method: method,
                    disabled: false
                }));
            });
            return;
        };

        const checkboxSelected = (checkBoxesSelected[0] as HTMLInputElement)?.name;

        dispatch(disableConsumerCheckboxes({
            consumer: type,
            method: checkboxSelected,
            disabled: true
        }));
    };

    const handleGuestInHomeChange = (event: CheckboxChangeEvent) => {
        const checkboxName = event.target.name;
        const checkboxState = event.target.checked;
        dispatch(setGuestInHomeConsumer({
            ...guestInHomeSegmentation,
            [checkboxName]: {
                checked: checkboxState,
                disabled: guestInHomeSegmentation[checkboxName].disabled,
                message: guestInHomeSegmentation[checkboxName].message,
                ...(checkboxName === "email") && {subject: guestInHomeSegmentation[checkboxName].subject},
                ...(checkboxName === "smsText") && {type: guestInHomeSegmentation[checkboxName].type},
            }
        }));
        updateConsumerCheckboxesState(CONSUMER_TYPES.GUEST_IN_HOME);
    };

    const handleUpcomingArrivalsChange = (event: CheckboxChangeEvent) => {
        const checkboxName = event.target.name;
        const checkboxState = event.target.checked;
        dispatch(setUpcomingArrivalsConsumer({
            ...upcomingArrivalsSegmentation,
            [checkboxName]: {
                checked: checkboxState,
                disabled: upcomingArrivalsSegmentation[checkboxName].disabled,
                message: upcomingArrivalsSegmentation[checkboxName].message,
                ...(checkboxName === "email") && {subject: upcomingArrivalsSegmentation[checkboxName].subject},
                ...(checkboxName === "smsText") && {type: upcomingArrivalsSegmentation[checkboxName].type},
            }
        }));
        updateConsumerCheckboxesState(CONSUMER_TYPES.UPCOMING_ARRIVALS);
    };

    const handleNewBookingChange = (event: CheckboxChangeEvent) => {
        const checkboxName = event.target.name;
        const checkboxState = event.target.checked;
        const disabled = newBookingSegmentation[checkboxName].disabled;
        dispatch(setNewBookingConsumer({
            ...newBookingSegmentation,
            [checkboxName]: {
                checked: checkboxState,
                disabled,
                message: newBookingSegmentation[checkboxName].message,
                ...(checkboxName === "email") && {subject: newBookingSegmentation[checkboxName].subject},
                ...(checkboxName === "smsText") && {type: newBookingSegmentation[checkboxName].type},
            }
        }));
        updateConsumerCheckboxesState(CONSUMER_TYPES.NEW_BOOKING);
    };

    const handleRadioChange = (event: RadioChangeEvent) => {
        const radioValue = event.target.value;
        const radioName = event.target.name;
        const radioStatus = event.target.checked;

        switch (radioName) {
            case CONSUMER_TYPES.GUEST_IN_HOME:
                dispatch(setGuestInHomeConsumer({
                    ...guestInHomeSegmentation,
                    email: {...guestInHomeSegmentation.email},
                    airbnbChat: {...guestInHomeSegmentation.airbnbChat},
                    smsText: {
                        ...guestInHomeSegmentation.smsText,
                        message: radioValue === "default" ? DEFAULT_SMS_MESSAGE : guestInHomeSegmentation.smsText.message,
                        checked: radioStatus,
                        type: radioValue,
                    }
                }));
                break;
            case CONSUMER_TYPES.UPCOMING_ARRIVALS:
                dispatch(setUpcomingArrivalsConsumer({
                    ...upcomingArrivalsSegmentation,
                    email: {...upcomingArrivalsSegmentation.email},
                    airbnbChat: {...upcomingArrivalsSegmentation.airbnbChat},
                    smsText: {
                        ...upcomingArrivalsSegmentation.smsText,
                        message: radioValue === "default" ? DEFAULT_SMS_MESSAGE : upcomingArrivalsSegmentation.smsText.message,
                        checked: radioStatus,
                        type: radioValue,
                    }
                }));
                break;
            case CONSUMER_TYPES.NEW_BOOKING:
                dispatch(setNewBookingConsumer({
                    ...newBookingSegmentation,
                    email: {...newBookingSegmentation.email},
                    airbnbChat: {...newBookingSegmentation.airbnbChat},
                    smsText: {
                        ...newBookingSegmentation.smsText,
                        message: radioValue === "default" ? DEFAULT_SMS_MESSAGE : newBookingSegmentation.smsText.message,
                        checked: radioStatus,
                        type: radioValue,
                    }
                }));
                break;
            default:
                break;
        }
    };

    const scrollToMyRefWithOffset = (input: string) => {
      
      let element = null;

      switch (input) {
        case 'guest_in_home':
          element = guestInHomeRef.current;
          break;
        case 'upcoming_arrivals':
          element = upcomingArrivalsRef.current;
          break;
        case 'new_booking':
          element = newBookingRef.current;
          break;
        default:
          break;
      }

      if (element) {
        window.scroll({
          top: element.offsetTop - 100, // Scroll 100px above the element
          behavior: 'smooth'
        });
      }
    };

    useEffect(() => {
        if (broadcast) {
            broadcast.attributes.consumer_segmentations.forEach(segmentation => {
                switch (segmentation.consumer_affected) {
                    case CONSUMER_TYPES.GUEST_IN_HOME:
                        dispatch(setTypeOfRecipients({
                            recipientType: CONSUMER_TYPES.GUEST_IN_HOME,
                            checked: true
                        }));
                        dispatch(setGuestInHomeConsumer({
                            email: {
                                checked: segmentation.email,
                                subject: segmentation.subject_line,
                                message: segmentation.email_message
                            },
                            airbnbChat: {
                                checked: segmentation.airbnb_chat,
                                message: segmentation.airbnb_chat_message
                            },
                            smsText: {
                                checked: segmentation.sms,
                                message: segmentation.sms_message
                            }
                        }));
                        
                        break;
                    case CONSUMER_TYPES.UPCOMING_ARRIVALS:
                        dispatch(setTypeOfRecipients({
                            recipientType: CONSUMER_TYPES.UPCOMING_ARRIVALS,
                            checked: true
                        }));
                        dispatch(setUpcomingArrivalsConsumer({
                            email: {
                                checked: segmentation.email,
                                subject: segmentation.subject_line,
                                message: segmentation.email_message
                            },
                            airbnbChat: {
                                checked: segmentation.airbnb_chat,
                                message: segmentation.airbnb_chat_message
                            },
                            smsText: {
                                checked: segmentation.sms,
                                message: segmentation.sms_message
                            }
                        }));
                        break;
                    case CONSUMER_TYPES.NEW_BOOKING:
                        dispatch(setTypeOfRecipients({
                            recipientType: CONSUMER_TYPES.NEW_BOOKING,
                            checked: true
                        }));
                        dispatch(setNewBookingConsumer({
                            email: {
                                checked: segmentation.email,
                                subject: segmentation.subject_line,
                                message: segmentation.email_message
                            },
                            airbnbChat: {
                                checked: segmentation.airbnb_chat,
                                message: segmentation.airbnb_chat_message
                            },
                            smsText: {
                                checked: segmentation.sms,
                                message: segmentation.sms_message
                            }
                        }));
                        break;
                    default:
                        break;
                }
            });
        }
    }, [broadcast]);

    useEffect(() => {
      if (consumerSegmentationError) {
        scrollToMyRefWithOffset(consumerSegmentationError.consumer_segmentation);
      }
    }, [consumerSegmentationError])

    return (
        <div className={localStyles.titleContainer}>
            <div className="review-container">
                <span className="broadcast-section-title">Who are we sending this to?</span>
                {isReview && !readOnly? <FormOutlined onClick={() => dispatch(setIsReview(false))} className="edit-icon" /> : null}
            </div>
            <>
                <div className={localStyles.checkboxContainer}>
                    <Checkbox className={isReview || readOnly? "checkboxDisabled" : "checkbox"} disabled={isReview || readOnly} checked={guestInHome.checked} name="guest_in_home" onChange={handleCheckboxChange} />
                    <span className={localStyles.checkboxMessage}>Guest(s) in Home</span>
                    <p className={localStyles.checkboxSubTitleMessage}>Guests who are in the home right now.</p>
                </div>
                <div className={localStyles.checkboxContainer}>
                    <Checkbox className={isReview || readOnly ? "checkboxDisabled" : "checkbox"} disabled={isReview || readOnly} checked={upcomingArrivals.checked} name="upcoming_arrivals" onChange={handleCheckboxChange} />
                    <span className={localStyles.checkboxMessage}>Upcoming Arrivals</span>
                    <p className={localStyles.checkboxSubTitleMessage}>All reservations that check into home(s) between { startDate || <i>start date</i> } to { endDate || <i>end date</i> }</p>
                </div>
                <div className={localStyles.checkboxContainer}>
                    <Checkbox className={isReview || readOnly? "checkboxDisabled" : "checkbox"} disabled={isReview || readOnly} checked={newBooking.checked} name="new_booking" onChange={handleCheckboxChange} />
                    <span className={localStyles.checkboxMessage}>New Reservations</span>
                    <p className={localStyles.checkboxSubTitleMessage}>Every new booking that checks into home(s) between { startDate || <i>start date</i> } to { endDate || <i>end date</i> }</p>
                </div>

                {/* Guest in Home */
                    guestInHome.checked
                        ? <div ref={guestInHomeRef}>
                          <ConsumerSegmentation
                              title="Guest(s) in Home"
                              type="guest_in_home"
                              subTitle="Guests who are in the home right now."
                              consumerSegmentation={guestInHomeSegmentation}
                              readOnly={readOnly}
                              inputEvent={handleEmailInputChange}
                              textareaEvent={handleTextareaChange}
                              checkboxEvent={handleGuestInHomeChange}
                              radioEvent={handleRadioChange}
                          />
                        </div> : null
                }
                {consumerSegmentationError && consumerSegmentationError.consumer_segmentation === 'guest_in_home' ?
                  <Alert
                      style={{marginTop: "15px", borderRadius: "5px"}}
                      type="error"
                      message={consumerSegmentationError.message}
                      banner
                  /> : null}

                {/* Upcoming Arrivals */
                    upcomingArrivals.checked
                        ? <div ref={upcomingArrivalsRef}>
                          <ConsumerSegmentation
                              title="Upcoming Arrivals"
                              subTitle={
                                <span>All reservations that check into home(s) between { startDate || <i>start date</i> } to { endDate || <i>end date</i> }</span>
                              }
                              type="upcoming_arrivals"
                              consumerSegmentation={upcomingArrivalsSegmentation}
                              readOnly={readOnly}
                              inputEvent={handleEmailInputChange}
                              textareaEvent={handleTextareaChange}
                              checkboxEvent={handleUpcomingArrivalsChange}
                              radioEvent={handleRadioChange}
                          /> 
                        </div>: null
                }
                {consumerSegmentationError && consumerSegmentationError.consumer_segmentation === 'upcoming_arrivals' ?
                  <Alert
                      style={{marginTop: "15px", borderRadius: "5px"}}
                      type="error"
                      message={consumerSegmentationError.message}
                      banner
                  /> : null}

                {/* New Reservations */
                    newBooking.checked
                        ? <div ref={newBookingRef}>
                            <ConsumerSegmentation
                              title="New Reservations"
                              subTitle={
                                <span>Every new booking that checks into home(s) between { startDate || <i>start date</i> } to { endDate || <i>end date</i> }</span>
                              }
                              type="new_booking"
                              consumerSegmentation={newBookingSegmentation}
                              readOnly={readOnly}
                              inputEvent={handleEmailInputChange}
                              textareaEvent={handleTextareaChange}
                              checkboxEvent={handleNewBookingChange}
                              radioEvent={handleRadioChange}
                          />
                        </div> : null
                }
                {consumerSegmentationError && consumerSegmentationError.consumer_segmentation === 'new_booking' ?
                  <Alert
                      style={{marginTop: "15px", borderRadius: "5px"}}
                      type="error"
                      message={consumerSegmentationError.message}
                      banner
                  /> : null}
            </>
        </div>
    )
};
