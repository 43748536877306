export const REQUIRED_FORM_INPUTS = "Incident Title, Start Date, End Date, Impacted Units";

export const CONSUMER_TYPES = {
    GUEST_IN_HOME: "guest_in_home",
    UPCOMING_ARRIVALS: "upcoming_arrivals",
    NEW_BOOKING: "new_booking"
};

export const CONSUMER_METHODS = ["email", "airbnbChat", "smsText"];

export const DEFAULT_SMS_MESSAGE = "IMPORTANT: Please check your email for a message from Vacasa regarding your reservation.";

export const defaultFilters = {
    title: "",
    is_active: true,
    pageNumber: 1,
    pageSize: 20,
};

export const timeToRedirectInMilliseconds = 5000;
export const timeToCloseNotificationInMilliseconds = 7000;
export const timeToRedirectInSeconds = timeToRedirectInMilliseconds / 1000;
export const timeToCloseNotificationInSeconds = timeToCloseNotificationInMilliseconds / 1000;
