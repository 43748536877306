"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImplicitFlowService = void 0;
const _ = require("lodash");
const axios_helper_1 = require("@vacasa/axios-helper");
const setup_1 = require("../../../setup");
const service_base_1 = require("../../base/service.base");
const jwt_decode_1 = require("jwt-decode");
class ImplicitFlowService extends service_base_1.Service {
    constructor(apiServiceUrl) {
        super();
        this.apiServiceUrl = apiServiceUrl;
        this.logger = setup_1.LoggingService === null || setup_1.LoggingService === void 0 ? void 0 : setup_1.LoggingService.getLogger("ImplicitFlowService");
    }
    get apiUrl() {
        return this.apiServiceUrl;
    }
    static getAuthenticatedUser() {
        const token = localStorage.getItem("idToken");
        return token && (0, jwt_decode_1.jwtDecode)(token);
    }
    async axiosInstance(backOff = 1000, maxAttempts = 5) {
        // @ts-ignore
        return (0, axios_helper_1.getExtendedAxios)({
            mediator: {
                getAuthCredentials: async () => {
                    return {
                        headers: {
                            "accept-language": "en-US",
                            authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                            "Content-Type": "application/vnd.api+json",
                        },
                    };
                },
            },
            retrier: {
                getBackoff: (error, attempts) => {
                    var _a, _b;
                    if (attempts >= maxAttempts) {
                        return -1;
                    }
                    (_a = this.logger) === null || _a === void 0 ? void 0 : _a.error("api error", {
                        message: error.toString(),
                    });
                    const status = _.get(error, "response.status", 0);
                    if (status == 400 || status == 404 || status == 422) {
                        return -1;
                    }
                    else if (status == 401 || status == 403) {
                        (_b = this.logger) === null || _b === void 0 ? void 0 : _b.error("Idp auth failed", {
                            error: error && error.toString(),
                        });
                    }
                    return typeof backOff == "function" ? backOff(attempts) : backOff;
                },
            },
        });
    }
}
exports.ImplicitFlowService = ImplicitFlowService;
