import React, {KeyboardEvent, useState, useEffect} from "react";
import localStyles from "./ImpactedUnits.module.scss";
import {Alert, Collapse, Select, Spin, Tag} from "antd";
import {RootState, useAppDispatch} from "../../store/store";
import {ImpactedUnit, cleanInvalidUnitCodes, setImpactedUnits, setImpactedUnitsDuplicated, setIsReview, validateUnit} from "../../store/broadcastSlice";
import {FormOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";
import {validateUnitCodesFormat} from "../../utils/validations/Validations";
import {clearSpecialCharacters, splitString} from "../../utils";
import {ValidUnitCode} from "@common/typing";
import {ReadOnlyProps} from "../../constants";

type UnitCodeInputProps = {
    label?: string;
    fontSize?: string;
    placeholder?: string;
};

const UnitCodeInput = ({label, fontSize = "14px", placeholder = ""}: UnitCodeInputProps) => {
    const dispatch = useAppDispatch();
    const isValidatingUnits = useSelector((state: RootState) => state.broadcast.actions.validatingUnits);
    const validUnits = useSelector((state: RootState) => state.broadcast.data.form.impactedUnits.validUnits);
    const invalidUnitCodes = useSelector((state: RootState) => state.broadcast.data.form.impactedUnits.invalidUnits);
    const [inputValue, setInputValue] = useState("");

    // Handlers
    const handleAddSingleUnitCode = async (event: KeyboardEvent<HTMLInputElement>) => {
        dispatch(cleanInvalidUnitCodes());
        const unitCode = clearSpecialCharacters(event.currentTarget.value.toUpperCase());
        setInputValue(unitCode);
        if (unitCode === "" || event.key !== "Enter") return;
        const response = await dispatch(validateUnit(unitCode)) as any;
        const validateUnitsAttributes = response?.payload?.data?.attributes;
        const validUnitsAdded = validateUnitsAttributes?.valid_units;
        const invalidUnitsAdded = validateUnitsAttributes?.invalid_units;

        if (validateDuplicatedUnitCodes(validUnitsAdded)) {
            dispatch(setImpactedUnitsDuplicated(true));
            return;
        }
        updateImpactedUnits(validUnitsAdded, invalidUnitsAdded);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const unitCode = (event.currentTarget.value).trim();
        if (!validateUnitCodesFormat(unitCode) && unitCode !== "") return;
        setInputValue(unitCode);
    };

    const handlePasteMultipleUnitCodes = async (event: React.ClipboardEvent<HTMLInputElement>): Promise<void> => {
        const listOfUnitCodes = event.clipboardData.getData("Text");
        if (listOfUnitCodes.trim() === "") return;
        setInputValue(listOfUnitCodes);
        const splitUnitCodes = splitString(listOfUnitCodes.trim().toUpperCase());
        const response = await dispatch(validateUnit(splitUnitCodes)) as any;
        const validateUnitsAttributes = response?.payload?.data?.attributes;
        const validUnitsAdded = validateUnitsAttributes?.valid_units;
        const invalidUnitsAdded = validateUnitsAttributes?.invalid_units;

        if (validateDuplicatedUnitCodes(validUnitsAdded)) {
            dispatch(setImpactedUnitsDuplicated(true));
            return;
        }
        updateImpactedUnits(validUnitsAdded, invalidUnitsAdded);
    };

    // Util Functions
    const validateDuplicatedUnitCodes = (validUnitsAdded: ValidUnitCode[]): boolean => {
        if (validUnits?.some(unit => validUnitsAdded?.some(unitAdded => unitAdded.id === unit.id))) {
            return true;
        }

        return false;
    };

    const updateImpactedUnits = (validUnitsAdded: string[], invalidUnitsAdded: string): void => {
        if (validUnitsAdded) {
            dispatch(setImpactedUnits({
                validUnits: [...validUnits, ...validUnitsAdded],
                invalidUnits: invalidUnitsAdded,
            }));
            setInputValue("");
            dispatch(setImpactedUnitsDuplicated(false));
            return;
        }

        if (invalidUnitCodes) {
            dispatch(setImpactedUnits({
                validUnits: [...validUnits],
                invalidUnits: invalidUnitsAdded,
            }));
            setInputValue("");
            dispatch(setImpactedUnitsDuplicated(false));
            return;
        }
    };

    return (
        <div className={localStyles.unitCodeContainer}>
            <label className="input-label" style={{fontSize}}>{label}</label>
            <div className={localStyles.unitCodeInputContainer}>
                <input
                    onChange={handleChange}
                    value={inputValue}
                    className={localStyles.unitCodeInput}
                    placeholder={placeholder}
                    disabled={isValidatingUnits}
                    type="text"
                    onKeyDown={handleAddSingleUnitCode}
                    onPaste={handlePasteMultipleUnitCodes}
                />
                {isValidatingUnits ? <Spin className={localStyles.unitCodeLoader} /> : null}
            </div>
        </div>
    );
};

export const ImpactedUnits = ({readOnly}: ReadOnlyProps): React.JSX.Element => {
    //RTK State
    const dispatch = useAppDispatch();
    const isReview = useSelector((state: RootState) => state.broadcast.states.isReview);
    const broadcast = useSelector((state: RootState) => state.broadcast.broadcastCase);
    const validUnits = useSelector((state: RootState) => state.broadcast.data.form.impactedUnits.validUnits);
    const invalidUnitCodes = useSelector((state: RootState) => state.broadcast.data.form.impactedUnits.invalidUnits);
    const impactedUnitsDuplicated = useSelector((state: RootState) => state.broadcast.states.impactedUnitsDuplicated);

    //Local State
    const listOfUnitCodes = ["CAH16", "CAH17", "CAH18", "CAH19", "CAH20"];
    const [unitCodes, setUnitCodes] = useState<string[]>([]);
    const [tagInput, setTagInput] = useState<string>('Search...');

    //Handlers
    const handleSelectChange = (value: string) => {
        if (unitCodes.includes(value)) {
            setTagInput('Search...');
            return;
        };

        setUnitCodes((prevSelectedItems) => [...prevSelectedItems, value]);
        setTagInput('Search...');
    };

    const handleTagInputChange = (value: string) => {
        setTagInput(value);
    };

    const handleCloseTag = (value: string) => {
        const unitCodesUpdated = validUnits.filter((unit) => unit?.code !== value);
        dispatch(setImpactedUnits({
            invalidUnits: [...invalidUnitCodes],
            validUnits: unitCodesUpdated,
        }));
    };

    //Store broadcast detail units in unitCodes
    useEffect(() => {
        if (broadcast) {
            const units: ImpactedUnit[] = broadcast.attributes.units.map(unit => ({
                id: unit.id,
                code: unit.code
            }));
            dispatch(setImpactedUnits({
                invalidUnits: [...invalidUnitCodes],
                validUnits: units,
            }));
        }
    }, [broadcast]);

    return (
        <>
            <div className="review-container">
                <span className="broadcast-section-title">Impacted Units {!isReview && !readOnly && <span className="required-field">(*)</span>}</span>
                {isReview ? <FormOutlined onClick={() => dispatch(setIsReview(false))} className="edit-icon" /> : null}
            </div>
            <div className={localStyles.impactedUnitsContainer}>
                {!isReview && !readOnly
                    ? <>
                        <div className={localStyles.inputsSection}>
                            <div className={localStyles.selectUnitCodes}>
                                <label className="input-label">Type</label>
                                <Select<string | number, {value: string; children: string}>
                                    showSearch
                                    style={{width: 200}}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (
                                        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    )}
                                    onChange={handleSelectChange}
                                    onSearch={handleTagInputChange}
                                    value={tagInput}
                                >
                                    {
                                        listOfUnitCodes.map((unitCode) => (
                                            <Select.Option key={unitCode} value={unitCode}>{unitCode}</Select.Option>
                                        ))
                                    }
                                </Select>
                            </div>
                            <UnitCodeInput label="Unit Code" placeholder="CAH15, CAH16, CAH17, etc." fontSize="12px" />
                            <span className={localStyles.pressEnterMessage}>Press ENTER to save</span>
                        </div>
                        <div className={localStyles.unitListSection}>
                            <span className="input-label">List of Units</span>
                            <div className={localStyles.unitListInput} aria-disabled>
                                {validUnits.map((unit: ImpactedUnit) => (
                                    <Tag className={localStyles.tag} key={unit.id} onClose={() => handleCloseTag(unit.code)} closable={true}>{unit.code}</Tag>
                                ))
                                }
                            </div>
                        </div>
                    </>
                    /* This is the "Review" view. If the impacted units are more than 15 then we display a Collapse Component */
                    : validUnits.length > 15 ?
                        <Collapse
                            className={localStyles.impactedUnitsCollapse}
                            items={[
                                {
                                    key: "1",
                                    label: "Click here to see the list of impacted Units",
                                    children:
                                        <ul className={localStyles.impactedUnitsList}>{validUnits.map((unit: ImpactedUnit) => (
                                            <li key={unit.id}>
                                                {unit.code}
                                            </li>))}
                                        </ul>,
                                },
                            ]}
                        /> :
                        <div>
                            <ul className={localStyles.impactedUnitsList}>
                                {validUnits.map((unit: ImpactedUnit) => (
                                    <li key={unit.id}>{unit.code}</li>
                                ))}
                            </ul>
                        </div>
                }
            </div>
            {invalidUnitCodes?.length ?
                <Alert
                    style={{marginTop: "15px", borderRadius: "5px"}}
                    type="error"
                    message={`Invalid Unit Code(s): ${invalidUnitCodes?.map((unitCode: string) => unitCode)}`}
                    banner
                /> : null}
            {impactedUnitsDuplicated ?
                <Alert
                    style={{marginTop: "15px", borderRadius: "5px"}}
                    type="warning"
                    message="Some of the Unit Codes were already added in the list."
                    banner
                /> : null}
        </>
    )
};
