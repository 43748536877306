import React from "react";
import localStyles from "./Button.module.scss";

type Props = {
    text: string;
    disabled?: boolean;
    onClick: () => void;
};

export const Button = ({text, disabled = false, onClick}: Props): React.JSX.Element => {
  return (
    <button
      className={disabled ? localStyles.buttonDisabled : localStyles.button}
      disabled={disabled}
      onClick={onClick}
    >
      {text}
    </button>
  )
};
