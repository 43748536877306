import React, {useState} from "react";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../store/store";
import localStyles from "./Modals.module.scss";
import {Modal, message, Spin} from "antd";
import EarlyCheckin from "../../assets/images/early_check_in.png";
import {setBroadcastErrorModalStatus, setDeactivateModalStatus, setUpdateDatesModalStatus, disableBroadcast} from "../../store/broadcastSlice";
import {WarningOutlined} from "@ant-design/icons";
import {resetFilters} from "../../store/queueSlice";
import {getUserInfo} from "../../utils/user-info/UserInfo";


export const DeactivateModal = (): React.JSX.Element => {
  const dispatch = useAppDispatch();
  const deactivateModalStatus = useSelector((state: RootState) => state.broadcast.modals.deactivateModal);
  const [stateDeactive, setStateDeactive] = useState(true);
  //broadcast detailed
  const broadcast = useSelector((state: RootState) => state.broadcast.broadcastCase);

  //Handlers
  const handleDeactivateModal = async () => {

    if(stateDeactive){
      setStateDeactive(false);
      await dispatch(disableBroadcast({id: broadcast?.id, payload: {disabled_by: getUserInfo()?.email}})).unwrap().then(() => {
        message.success("Broadcast disabled successfully!", 7);
      }).catch((error) => {
        message.error("Broadcast disabled failed, please try again!", 7);
      }).finally(() => {
        setStateDeactive(true);
        handleCloseModal();
      });
    }
    
  };

  const handleCloseModal = () => {
    dispatch(setDeactivateModalStatus(false));
  };
  

  return (
    <Modal
      open={deactivateModalStatus}
      okButtonProps={{style: {display: "none"}}}
      cancelButtonProps={{style: {display: "none"}}}
      onCancel={handleCloseModal}
    >
      <div className={localStyles.modalContent}>
      <img src={EarlyCheckin} className={localStyles.imgBody} alt="imgBody" />
        {stateDeactive ? 
          <div>
          <p className={localStyles.titleMessage}>Are you sure?</p>
          <p className={localStyles.descriptionMessage}>Deactivating this incident cannot be undone.</p>
          <div className={localStyles.buttonsContainer}>
            <button onClick={handleDeactivateModal}  className={localStyles.primaryButton}>Yes, Deactivate</button>
          </div>
          <span onClick={handleCloseModal} className={localStyles.subTitleMessage}>I've changed my mind</span>
          </div> :
          <div>
            <p className={localStyles.titleMessage}>Deactivating</p>
            <p className={localStyles.descriptionMessage}>Deactivating this incident may delay some time.</p>
            <Spin />
            <p className={localStyles.descriptionMessage}>We are disabling the broadcast...please wait</p>
          </div>
        }
      </div>
      
    </Modal>
  );
};

export const UpdateDatesModal = (): React.JSX.Element => {
  //RTK State
  const dispatch = useAppDispatch();
  const updateDatesModalStatus = useSelector((state: RootState) => state.broadcast.modals.updateDatesModal);

  //Handlers
  const handleCloseModal = () => {
    dispatch(setUpdateDatesModalStatus(false));
  };

  return (
    <Modal
      open={updateDatesModalStatus}
      okButtonProps={{style: {display: "none"}}}
      cancelButtonProps={{style: {display: "none"}}}
      onCancel={handleCloseModal}
    >
      <div className={localStyles.modalContent}>
        <img src={EarlyCheckin} className={localStyles.imgBody} alt="imgBody" />
        <p className={localStyles.titleMessage}>Don't worry</p>
        <p className={localStyles.descriptionMessage}>By updating the dates, the system will make sure not to re-email anyone who doesn’t need to be notified.</p>
        <div className={localStyles.buttonsContainer}>
          <button className={localStyles.primaryButton}>Update Dates</button>
        </div>
        <span onClick={handleCloseModal} className={localStyles.subTitleMessage}>I've changed my mind</span>
      </div>
    </Modal>
  );
};

export const BroadcastErrorModal = (): React.JSX.Element => {
  //RTK State
  const dispatch = useAppDispatch();
  const broadcastErrorModal = useSelector((state: RootState) => state.broadcast.modals.broadcastErrorModal);

  //Handlers
  const handleCloseModal = () => {
    dispatch(setBroadcastErrorModalStatus(false));
  };

  const handleRefreshPage = () => {
    dispatch(resetFilters());
    window.location.href.replace(window.location.search,'');
    window.location.reload();
  };

  return (
    <Modal
      open={broadcastErrorModal}
      okButtonProps={{style: {display: "none"}}}
      cancelButtonProps={{style: {display: "none"}}}
      onCancel={handleCloseModal}
      closeIcon={false}
      centered
    >
      <div className={localStyles.modalContent}>
        <p className={localStyles.titleMessage}>Broadcast Error <WarningOutlined style={{color: '#d62e4f'}} /></p>
        <p className={localStyles.descriptionMessage}>Looks like something was wrong. Please refresh the page pressing the button below and try again.
          If the error persist, contact with the administrator</p>
        <div className={localStyles.buttonsContainer}>
          <button onClick={handleRefreshPage} className={localStyles.primaryButton}>Refresh page</button>
        </div>
      </div>
    </Modal>
  );
};

export const BroadcastInputErrorModal = ({ message, onClose, notClose }: {message?: string | null, onClose: Function, notClose?: boolean | null }): React.JSX.Element => {

  //Handlers
  const handleCloseModal = () => {
    onClose();
  };

  return (
    <Modal
      open={message !== null && message !== ''}
      okButtonProps={{style: {display: "none"}}}
      cancelButtonProps={{style: {display: "none"}}}
      onCancel={handleCloseModal}
      closeIcon={false}
      centered
    >
      <div className={localStyles.modalContent}>
        <p className={localStyles.titleMessage}>Error <WarningOutlined style={{color: '#d62e4f'}} /></p>
        <p className={localStyles.descriptionMessage}>{message}</p>
        <p className={localStyles.descriptionMessage}>
          Ask for help in <strong>#tmp-vacasa-broadcast-service</strong>.
        </p>
        { !notClose && (
          <div className={localStyles.buttonsContainer}>
            <button onClick={handleCloseModal} className={localStyles.primaryButton}>Close</button>
          </div>
        ) }
      </div>
    </Modal>
  );
};


export const BroadcastSuccessModal = ({ message, onClose }: {message?: string | null, onClose: Function }): React.JSX.Element => {
  //RTK State
  const dispatch = useAppDispatch();

  //Handlers
  const handleCloseModal = () => {
    onClose();
  };

  const handleRefreshPage = () => {
    dispatch(resetFilters());
    window.scrollTo(0, 0);
    window.location.href.replace(window.location.search,'');
    window.location.reload();
  };

  return (
    <Modal
      open={message !== null && message !== ''}
      okButtonProps={{style: {display: "none"}}}
      cancelButtonProps={{style: {display: "none"}}}
      onCancel={handleCloseModal}
      closeIcon={false}
      centered
    >
      <div className={localStyles.modalContent}>
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="165" height="117" viewBox="0 0 165 117" fill="none">
              <path d="M135.167 46.7346C115.939 34.4155 115.804 7.55573 80.3938 6.61328C80.3938 6.61328 30.4274 7.01718 30.1565 45.7248C30.0211 68.0069 30.4274 66.8625 25.8911 82.7495C21.8288 96.9535 30.2919 104.695 45.5256 108.263L45.0517 95.0013C61.1655 95.2033 77.1439 95.0013 93.3255 94.1935C93.4609 96.6842 94.341 104.762 94.8827 109.878L103.82 109.138C141.938 105.368 154.599 59.1883 135.167 46.7346Z" fill="#FFF4D0"/>
              <path d="M79.7437 31.0303C79.7437 31.0303 80.0133 42.4117 84.3939 44.5119C88.7746 46.612 91.1334 54.5384 85.6744 60.0259L72.5999 41.7343L79.7437 31.0303Z" fill="#395C6E"/>
              <path d="M92.8737 93.8399C92.806 91.7633 92.6706 89.5527 92.4676 87.2752C92.1291 83.5909 91.7229 79.6386 91.1137 75.7534C90.6399 72.9399 90.0983 70.0595 89.4891 67.313C88.5604 63.2989 87.2691 59.3755 85.6307 55.5902C82.1107 47.8867 77.1015 42.4607 69.7231 42.2598C53.8832 41.6569 46.3017 48.2886 42.9848 56.3941C41.4099 60.4328 40.6065 64.7257 40.6155 69.0547C40.6068 71.7656 40.7878 74.4739 41.1571 77.1601C41.4114 79.4444 41.8414 81.7062 42.4432 83.9258L43.1201 87.2752L44.6094 94.6437L45.0832 107.84C57.877 110.855 75.4769 110.922 94.4306 109.448C93.8891 104.357 93.0091 96.3184 92.8737 93.8399Z" fill="#BBC7D0"/>
              <path d="M84.6325 20.3223V19.921C84.3615 16.7779 82.6679 13.4342 77.2485 12.0967C65.3256 9.22107 61.9385 22.9304 61.9385 22.9304L62.2772 28.2135L62.4127 42.8591L74.3355 51.8871L74.742 42.5247C79.484 41.8559 85.8519 37.8435 84.6325 20.3223Z" fill="#F6CAAB"/>
              <path d="M64.9845 32.6338C64.9845 32.6338 57.6162 35.2802 58.4504 27.47C59.2845 19.6598 64.9845 24.8236 64.9845 28.6319C64.9845 32.4402 64.9845 32.6338 64.9845 32.6338Z" fill="#F6CAAB"/>
              <path d="M82.7538 14.9852C71.9613 2.06986 50.991 10.234 50.991 27.4321C50.991 27.4321 35.4852 38.9421 37.1929 53.2628C39.7886 75.5467 54.8162 70.5278 64.1743 61.6945C64.7728 61.8918 65.391 62.0264 66.0186 62.0961C66.8324 60.5513 67.562 58.9653 68.2044 57.3448C70.3219 54.4673 71.6881 51.6567 71.8247 49.4484C72.5077 35.1947 62.8081 33.7894 62.8081 33.7894L65.677 23.9523C78.6554 24.3538 82.7538 14.9852 82.7538 14.9852Z" fill="#395C6E"/>
              <path d="M69.1537 80.7828C65.3201 80.6489 62.8989 81.0507 60.6794 81.0507C56.4423 81.1846 51.8016 81.3185 48.3716 81.1846C44.9415 81.0507 42.8566 80.8498 42.722 80.3142L43.2601 79.7786L44.9415 78.1048C47.3627 75.8285 51.1963 72.2131 54.6264 69.4681L57.3166 67.5265C59.8488 65.8389 62.1347 63.8112 64.1095 61.5009C69.6918 54.538 66.1944 49.6506 66.1944 49.6506C66.1944 49.6506 62.1591 43.9597 51.3308 50.3201C47.3796 52.7726 43.7434 55.6953 40.5026 59.0237L39.2247 60.2289C36.0064 63.4466 32.975 66.8443 30.1451 70.4054C25.5045 76.3641 22.2762 82.3897 23.6885 86.8754C25.1009 91.3611 30.7504 93.9053 43.9326 93.5705H45.5468C52.4742 93.3697 57.7202 92.968 61.621 92.5662C73.9289 91.2272 73.5926 89.3526 73.5926 89.3526C73.5926 89.3526 74.1979 80.9167 69.1537 80.7828Z" fill="#98AAB6"/>
              <path d="M92.1929 87.7843C91.8594 85.1378 90.1251 82.6897 87.3902 80.374L73.4492 84.0792C70.581 83.8145 69.7138 85.4686 70.1807 88.4459C70.1807 88.4459 77.985 91.4894 83.7882 88.2474L87.8572 90.0338C91.1256 90.1 93.06 89.5707 92.1929 87.7843Z" fill="#F6CAAB"/>
              <path d="M147.738 58.5H101.316L96.3976 88.8255L77.8018 89.3707C77.8018 89.3707 75.6457 90.8699 77.2628 92.5736C77.3301 92.6417 140.327 92.301 140.327 92.301L147.738 58.5Z" fill="#E2E6EC"/>
              <path d="M101.478 23.9127H107.184C107.184 23.9127 109.154 23.7753 109.154 25.2182V28.6538C109.154 30.0967 107.659 29.9593 107.659 29.9593V31.5396L106.029 29.9593H101.07C100.459 29.8906 99.5076 29.6844 99.5076 28.6538V25.2182C99.5076 23.7753 101.478 23.9127 101.478 23.9127Z" fill="#F1BB9A"/>
              <path d="M98.7833 13.238H90.5855C90.5855 13.238 87.8306 12.9807 87.8306 14.9103V19.6056C87.8306 21.5352 90.5855 21.2779 90.5855 21.2779V23.4005L92.803 21.2779H99.7912C100.598 21.1493 101.538 20.7634 101.538 19.6699V14.9103C101.538 12.9807 98.7833 13.238 98.7833 13.238Z" fill="#E7824F"/>
              <ellipse cx="91.1307" cy="17.0414" rx="0.761539" ry="0.763043" fill="white"/>
              <ellipse cx="99.2537" cy="17.0414" rx="0.761536" ry="0.763043" fill="white"/>
              <ellipse cx="94.6846" cy="17.0414" rx="0.761536" ry="0.763043" fill="white"/>
              <path d="M95.8386 31.5513H90.992C90.992 31.5513 89.3538 31.4203 89.3538 32.5994V35.3507C89.3538 36.5298 90.992 36.3333 90.992 36.3333V37.6434L92.289 36.3333H96.4529C97.0184 36.3333 97.4768 35.8934 97.4768 35.3507V32.5339C97.4768 31.3548 95.8386 31.5513 95.8386 31.5513Z" fill="#E7824F"/>
              <ellipse cx="90.8768" cy="34.5917" rx="0.50769" ry="0.508696" fill="white"/>
              <ellipse cx="95.4462" cy="34.0829" rx="0.507694" ry="0.508696" fill="white"/>
              <ellipse cx="93.4154" cy="34.0829" rx="0.507694" ry="0.508696" fill="white"/>
              <ellipse cx="101.538" cy="27.4696" rx="0.50769" ry="0.508696" fill="white"/>
              <ellipse cx="107.123" cy="27.4696" rx="0.50769" ry="0.508696" fill="white"/>
              <ellipse cx="104.585" cy="27.4696" rx="0.507694" ry="0.508696" fill="white"/>
              <path d="M61.5219 25.6812L62.1716 14.381C62.1716 14.381 64.1206 8.99373 68.5384 8.13965L65.355 8.40244C65.355 8.40244 61.5219 10.8333 60.9372 14.1839C60.3525 17.5346 60.4174 25.944 60.4174 25.944L61.5219 25.6812Z" fill="#E2E6EC"/>
              <path d="M77.4171 34.6833L65.4361 29.3561C65.4894 29.1062 65.5123 28.8506 65.5041 28.595C65.7084 26.9969 64.9841 25.4231 63.6463 24.558C62.3085 23.6929 60.5989 23.6929 59.2611 24.558C57.9233 25.4231 57.199 26.9969 57.4033 28.595C57.4031 29.6897 57.8356 30.7386 58.6036 31.5061C59.3716 32.2736 60.4108 32.6955 61.4877 32.6769C62.7185 32.6715 63.8764 32.0831 64.6192 31.0857L77.0087 36.0669C77.6894 36.4129 77.8937 34.8908 77.4171 34.6833Z" fill="white"/>
              <ellipse cx="61.4308" cy="28.4868" rx="2.53846" ry="2.54348" fill="#E2E6EC"/>
          </svg>
        </div>
        <p className={localStyles.titleMessage}>Success</p>
        <p className={localStyles.descriptionSuccessMessage}>{message}</p>
        <div className={localStyles.buttonsSuccessContainer}>
          <button onClick={handleCloseModal} className={localStyles.modalButton}>Go to Active Broadcasts Grid</button>
        </div>
        <div className={localStyles.linksContainer}>
          <button onClick={handleRefreshPage} className={localStyles.modalButtonLink}>Submit another broadcast</button>
        </div>
      </div>
    </Modal>
  );
};
